import './App.sass';
import { useTranslation } from "react-i18next";
import { useCustomerTheme } from './Theme';

interface Properties {
  title: string;
  className?: string;
  children?: any;
}

export default function Section(properties: Properties) {
  const { t } = useTranslation();
  const { theme } = useCustomerTheme();

  return <div className={`section ${properties.className || ''}`}>
    <div className="title" style={{ color: theme.primaryDark }}>{properties.title}</div>
    <div className='marginTopM'>
      {properties && properties.children
        ?
        (properties.children)
        :
        <></>
      }
    </div>
  </div>;
}
