import { EntityQuery, Query, is, LegalPerson } from "@marc.gille-sepehri/tri-model";
import { userAPI } from "../UserAPI";
import { useEffect, useRef, useState } from "react";
import { ContextualMenu, SearchBox } from "@fluentui/react";

interface Properties {
    setLegalPerson?: (legalPerson: any) => void
}

export default function LegalPersonPicker(properties: Properties) {
    const linkRef = useRef(null);
    const [filter, setFilter] = useState('');
    const [legalPersons, setLegalPersons] = useState([]) as any;
    const [addressFilter, setLegalPersonFilter] = useState() as any;
    const [menuOpen, setMenuOpen] = useState([]) as any;

    useEffect(() => {
        const debounce = setTimeout(async () => {
            console.log(filter);

            if (filter && filter.trim().length > 2) {
                // TODO Pushed to server as canned service - or reuse existing

                const persons = await userAPI.query(new Query(new EntityQuery(LegalPerson.type, [], undefined,
                    is('name').equalRegExp(`(?i)${filter}.*`))));

                setLegalPersons(persons);
                setMenuOpen(true);
            }
        }, 1500);

        return () => clearTimeout(debounce)
    }, [filter])

    const filterLegalPersons = async (filter: string) => {
        setFilter(filter);
        setLegalPersonFilter(null);
        setLegalPersons([]);

        if (properties.setLegalPerson) {
            properties.setLegalPerson(null);
        }
    };

    return <div>
        <SearchBox defaultValue="Namen, Handelsregisternummer oder Strasse eingeben" value={addressFilter ? `${addressFilter.name}` : filter} ref={linkRef} onChange={(event: any, filter: any) => filterLegalPersons(filter)}
            styles={{ root: { width: 450 } }}></SearchBox>
        <ContextualMenu
            items={legalPersons.map((legalPerson: LegalPerson) => {
                return {
                    key: `${legalPerson.name}`, text: `${legalPerson.name} (${legalPerson.tradeRegisterId}, ${legalPerson.tradeRegisterCity})`, onClick: () => {
                        setLegalPersonFilter(legalPerson);
                        setMenuOpen(false);

                        if (properties.setLegalPerson) {
                            properties.setLegalPerson(legalPerson);
                        }
                    }
                };
            })}
            hidden={!menuOpen}
            target={linkRef}
            onItemClick={() => setMenuOpen(false)}
            onDismiss={() => setMenuOpen(false)}
        />
    </div>
}