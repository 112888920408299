import './App.sass';
import { useEffect, useState, useRef, useCallback } from 'react';
import { PrimaryButton, TextField, IconButton, FontIcon } from "@fluentui/react";
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { makeStyles, shorthands } from "@fluentui/react-components";
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { UserAvatar } from './UserAvatar';
import { BotAvatar } from './BotAvatar';
import { ResourceDetails } from './ResourceDetails';
import { useSpeech } from './Speech';
import { useAtom } from 'jotai'
import { conversationAtom } from "./State";
import { requestsAtom } from "./State";
import { requestIndexAtom } from "./State";
import { userAPI } from './UserAPI';
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const userContext = { firstName: 'Marc', lastName: 'Gille-Sepehri', email: 'marc@thing-it.com', mesh: '65093dfececde3b84a486809' }
const iconClass = {
    fontSize: 30,
    height: 30,
    width: 30,
};
const suggestionIconStyles = [
    mergeStyles({ color: 'darkblue', ...iconClass }),
    mergeStyles({ color: 'darkred', ...iconClass }),
    mergeStyles({ color: 'darkgreen', ...iconClass }),
    mergeStyles({ color: 'purple', ...iconClass })];

const useStyles = makeStyles({
    flex: {
        display: 'flex',
        marginBottom: '15px',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'end',
    },
    location: {
        flexGrow: '1',
        marginRight: '10px',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '10px',
    },
    dummyUX: {
        width: '300px',
        marginTop: '10px',
    },
    messageContainer: {
        display: 'flex',
        //flexGrow: '1',
        marginTop: '20px',
        marginBottom: '20px',
    },
    userMessageContainer: {
        display: 'flex',
        justifyContent: 'end',
        //flexGrow: '1',
        marginTop: '20px',
        marginBottom: '20px',
    },
    chatBotMessage: {
        color: 'black',
        position: 'relative',
        ...shorthands.padding('10px'),
        ...shorthands.borderRadius('5px'),
        marginLeft: '10px',
    },
    chatBotMessageArrow: {
    },
    chatBotSuggestion: {
        backgroundColor: '#9483F1',
        color: 'white',
        flexGrow: '1',
        position: 'relative',
        ...shorthands.padding('10px'),
        ...shorthands.borderRadius('5px'),
        marginLeft: '10px',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    },
    chatBotSuggestionArrow: {
        width: '0',
        height: '0',
        ...shorthands.borderTop('8px', 'solid', 'transparent'),
        ...shorthands.borderBottom('8px', 'solid', 'transparent'),
        ...shorthands.borderRight('8px', 'solid', '#9483F1'),
        position: 'absolute',
        left: '-7px',
        top: '13px',
        zIndex: '999',
    },
    userMessage: {
        backgroundColor: '#DFF0FB',
        color: 'black',
        position: 'relative',
        ...shorthands.padding('10px'),
        ...shorthands.borderRadius('5px'),
        marginRight: '10px',
    },
    userMessageArrow: {
    },
    inputTextField: {
        flexGrow: '1',
        marginRight: '10px'
    },
    microphoneButton: {
        zIndex: '999',
    },
    settingsButton: {
        position: 'absolute',
        right: '10px',
        top: '10px',
    }
});

const options = [
    { key: '6K.2.22', text: '6K.2.22' },
    { key: '3.5.115', text: '3.5.115' },
    { key: 'Time Square', text: 'Time Square' },
];

const profileOptions = [
    { key: 'officeWorker', text: 'Office Worker' },
    { key: 'analyst', text: 'Analyst' },
];

export function ChatBot() {
    const { t } = useTranslation();
    const textField = useRef();
    const messagesContainer = useRef();
    const messagesEndRef = useRef();
    const [authentication] = useState(null);
    const [selectedItem] = useState(options[0]);
    const [selectedProfile] = useState(profileOptions[0]);
    const [agent, setAgent] = useState();
    const [id] = useState(null);
    const [skills, setSkills] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState([]);
    const [hideDialog, setHideDialog] = useState(true);
    const [skillAgentResponse, setSkillAgentResponse] = useState(null);
    const [requests, setRequests] = useAtom(requestsAtom);
    const [requestIndex, setRequestIndex] = useAtom(requestIndexAtom);
    const [inputValue, setInputValue] = useState(null);
    const [pendingRequest, setPendingRequest] = useState(false);
    const [conversation, setConversation] = useAtom(conversationAtom);
    const [locale] = useState(i18next.resolvedLanguage);
    const [, setInputFocussed] = useState(false);
    const [transcribing, setTranscribing] = useState(false);
    const [resetSuggestionsTimer, setResetSuggestionsTimer] = useState(true);

    useSpeech((text, final) => {
        setInputValue(text);

        if (final) {
            setTranscribing(false);
        }
    }, transcribing);

    const styles = useStyles();

    const toggleHideDialog = useCallback(() => {
        setHideDialog(!hideDialog);
    }, [hideDialog]);

    const triggerEvent = () => {
        userAPI.triggerEvent(id).then((result) => {
            result.json().then((data) => {
                console.log('Response >>>', data);
            });
        }).catch((error) => {
            console.error(error);
        });
    };

    const recordRequest = useCallback((request) => {
        if (request) {
            setRequests([...requests, request]);
            setRequestIndex(requests.length);
        }
    }, [requests])

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const submitInput = useCallback(async (input) => {
        if (!input || input.trim().length === 0) {
            return;
        }

        input = capitalizeFirstLetter(input);

        console.log('Submitting input >>>', input);

        const allowsConfirmation = conversation.length > 0 && (conversation[conversation.length - 1].type === 'botSuggestion' ||
            conversation[conversation.length - 1].confirmation);
        let newConversation = [...conversation, {
            type: 'userMessage',
            content: input
        }];

        setConversation(newConversation);
        setInputValue(null);

        if (allowsConfirmation) {
            if (input.trim().toLowerCase() === 'yes' && conversation[conversation.length - 1].type === 'botSuggestion') {
                input = conversation[conversation.length - 1].prompt;
            } else if (input.trim().toLowerCase() === 'no') {
                textField.current.focus();

                return;
            }
        } else {
            recordRequest(input);
        }

        setPendingRequest(true);

        try {
            const payload = await userAPI.submitRequest(id, agent, selectedProfile.key, input,
                { location: { spaceName: selectedItem.key }, user: userContext, locale });

            console.log('Payload >>>', payload);

            const newSkillAgentResponse = payload.output ? payload.output : payload;

            setAgent(payload.agent);
            setSkillAgentResponse(newSkillAgentResponse);

            if (newSkillAgentResponse.skill && newSkillAgentResponse.followUp === 'clientAction') {
                toggleHideDialog();
            } else {
                setConversation([...newConversation, {
                    type: 'botMessage',
                    content: newSkillAgentResponse.message,
                    confirmation: newSkillAgentResponse.followUp === 'confirmation',
                    resources: newSkillAgentResponse.resources,
                }]);
            }

            setShowSuggestions(false);
            setPendingRequest(false);

            return newSkillAgentResponse.message;
        } catch (error) {
            console.error(error);

            setConversation([...newConversation, {
                type: 'botMessage',
                content: 'Ich scheine Kommunikationsprobleme zu haben.'
            }]);

            setShowSuggestions(false);
            setPendingRequest(false);

            return 'Ich scheine Kommunikationsprobleme zu haben.';
        }
    }, [agent, authentication, id, conversation, recordRequest, selectedItem.key, selectedProfile.key, toggleHideDialog]);

    const keyEvent = (event) => {
        if (event.key === 'Enter') {
            submitInput(inputValue);
        } else if (event.key === 'ArrowUp') {
            const newRequestIndex = Math.max(requestIndex - 1, 0);

            setRequestIndex(newRequestIndex);
            setInputValue(requests[newRequestIndex]);
            event.preventDefault();

            return false;
        } else if (event.key === 'ArrowDown') {
            const newRequestIndex = Math.min(requestIndex + 1, requests.length - 1);

            setRequestIndex(newRequestIndex);
            setInputValue(requests[newRequestIndex]);
            event.preventDefault();

            return false;
        }
    };

    const clientActionTitle = (skillAgentResponse) => {
        if (!skillAgentResponse || !skillAgentResponse.skill) {
            return 'Client Action';
        }

        switch (skillAgentResponse.skill.id) {
            case 'clientAction/wayfinding': return 'Wayfinding';
            case 'clientAction/showDetails': return 'Resource Details';
            default: return 'Client Action';
        }
    }

    const selectSuggestions = (skills) => {
        const shuffledSkills = skills.sort(() => 0.5 - Math.random());
        const shuffledIconStyles = suggestionIconStyles.sort(() => 0.5 - Math.random());

        setSuggestions(shuffledSkills.slice(0, 4).map((skill, index) => {
            return { ...skill, iconStyles: shuffledIconStyles[index], index: Date.now() % skill.examples.length }
        }));
    };

    useEffect(() => {
        const f = async () => {
            const newSkills = await userAPI.getSkills();

            setSkills(newSkills);
            selectSuggestions(newSkills);
        };

        f();
    }, []);

    useEffect(() => {
        if (!resetSuggestionsTimer) {
            return;
        }

        let interval;

        if (skills.length > 0) {
            interval = setInterval(() => {
                selectSuggestions(skills);
            }, 10000);
        }

        return () => clearInterval(interval);
    }, [skills, resetSuggestionsTimer]);

    // TODO Cleanup!

    useEffect(() => {
        // const socket = io(`${baseUrl}`);

        // socket.on("connect", () => {
        //     setId(socket.id);

        //     socket.on("suggestion", (suggestion) => {
        //         setConversation([...conversation, {
        //             type: 'botSuggestion',
        //             content: suggestion.message,
        //             prompt: suggestion.prompt,
        //         }]);

        //         textField.current.focus();
        //     });

        //     socket.on("message", (message) => {
        //         setConversation([...conversation, {
        //             type: 'botMessage',
        //             content: message
        //         }]);
        //     });
        // });
    }, [conversation]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [conversation, showSuggestions]);

    useEffect(() => {
        if (!textField.current) {
            return;
        }

        if (!pendingRequest) {
            textField.current.focus();
        } else {
            textField.current.blur();
        }
    }, [pendingRequest]);

    // useEffect(() => {
    //     speechController.current.recognizingCallback = (text) => {
    //         setInputValue(text);
    //     }
    //     speechController.current.recognizedCallback = submitInput;
    //     speechController.current.stateChangeCallback = (state) => {
    //         setSpeechControllerState(state);
    //     }
    //     speechController.current.listen();
    // }, [speechController, submitInput]);


    const submitSuggestion = (request) => {
        setInputValue(request);
        setShowSuggestions(false);
        submitInput(request);
    }

    return (
        <div className="chatBot">
            <div className="chatContent">
                {/* <div className={styles.settingsButton}>
                    <IconButton iconProps={{ iconName: volumeOn ? 'Volume3' : 'Volume0' }} onClick={() => {
                        if (speechController.current.volumeOn) {
                            speechController.current.pauseSpeaking();
                        }

                        speechController.current.volumeOn = !volumeOn;

                        setVolumeOn(!volumeOn);
                    }} ariaLabel="Voice" />
                </div> */}
                {/* <div className={styles.title}>
                            {selectedProfile.key === 'analyst' ? 'Thing-it Workplace Analytics Assistant' : 'Thing-it Workplace Assistant'}</div>
                        <div>{speechControllerState}</div> */}
                <div className="messagesContainer" ref={messagesContainer}>
                    <div className='messages'>
                        {conversation.map((message, index) => {
                            return (() => {
                                switch (message.type) {
                                    case 'botMessage': return <div key={index}>
                                        <div className={styles.messageContainer}>
                                            <BotAvatar></BotAvatar>
                                            <div className={styles.chatBotMessage}>
                                                <div className={styles.chatBotMessageArrow}></div>
                                                <div>{message.content}</div>
                                            </div>
                                        </div>
                                        {message.resources
                                            ?
                                            <ResourceDetails resources={message.resources}></ResourceDetails>
                                            :
                                            <></>}
                                        {conversation[conversation.length - 1] === message && message.confirmation
                                            ?
                                            <div className={styles.flexEnd}>
                                                <PrimaryButton onClick={() => submitInput('yes')} text="Yes" />
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>;
                                    case 'botSuggestion': return <div key={index}>
                                        <div className={styles.messageContainer}>
                                            <BotAvatar></BotAvatar>
                                            <div className={styles.chatBotSuggestion}>
                                                {message.content}
                                            </div>
                                        </div>
                                        {message.resources
                                            ?
                                            <ResourceDetails resources={message.resources}></ResourceDetails>
                                            :
                                            <></>}
                                        {conversation[conversation.length - 1] === message
                                            ?
                                            <div className={styles.flexEnd}>
                                                <PrimaryButton onClick={() => submitInput('yes')} text="Yes" />
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>;
                                    case 'userMessage': return <div key={index} className={styles.userMessageContainer}>
                                        <div className={styles.userMessage}>
                                            {message.content}
                                        </div>
                                        <UserAvatar></UserAvatar>
                                    </div>;
                                    default: return <></>
                                }
                            })();
                        })}
                        {pendingRequest ?
                            <div className={styles.messageContainer}>
                                <BotAvatar></BotAvatar>
                                <div className={styles.chatBotMessage}>
                                    <div className={styles.chatBotMessageArrow}></div>
                                    <div className="spinner-box">
                                        <div className="pulse-container">
                                            <div className="pulse-bubble pulse-bubble-1"></div>
                                            <div className="pulse-bubble pulse-bubble-2"></div>
                                            <div className="pulse-bubble pulse-bubble-3"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <></>}
                        <div ref={messagesEndRef}></div>
                    </div>
                </div>
                {conversation.length === 0 || showSuggestions ?
                    <>
                        {conversation.length === 0
                            ?
                            < div className='logo'>
                                <img src="/logo.png" alt="logo"></img>
                            </div>
                            :
                            <div className="paddingM displayFlex justifyContentEnd gapsM ">
                                <IconButton iconProps={{ iconName: 'Refresh' }} title="Refresh" ariaLabel="Refresh" onClick={() => {
                                    if (skills) { selectSuggestions(skills) }

                                    setResetSuggestionsTimer(true);
                                }} />
                                <IconButton iconProps={{ iconName: 'Cancel' }} title="Close" ariaLabel="Close" onClick={() => setShowSuggestions(false)} />
                            </div>
                        }
                        {
                            suggestions && suggestions.length > 0 &&
                            <>
                                <div className="suggestions">
                                    <div key={suggestions[0].id} className='suggestion rollOut' onClick={() => submitSuggestion(suggestions[0].examples[suggestions[0].index])}>
                                        <FontIcon iconName={suggestions[0].icon} className={suggestions[0].iconStyles} ariaLabel="send" />
                                        <div className='request'>{suggestions[0].examples[suggestions[0].index]}</div>
                                    </div>
                                    <div key={suggestions[1].id} className='suggestion rollOut' onClick={() => submitSuggestion(suggestions[1].examples[suggestions[1].index])}>
                                        <FontIcon iconName={suggestions[1].icon} className={suggestions[1].iconStyles} ariaLabel="send" />
                                        <div className='request'>{suggestions[1].examples[suggestions[1].index]}</div>
                                    </div>
                                    <div key={suggestions[2].id} className='suggestion rollOut' onClick={() => submitSuggestion(suggestions[2].examples[suggestions[2].index])}>
                                        <FontIcon iconName={suggestions[2].icon} className={suggestions[2].iconStyles} ariaLabel="send" />
                                        <div className='request'>{suggestions[2].examples[suggestions[2].index]}</div>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                    :
                    <></>
                }
                <div className="inputContainer displayFlex flexDirectionColumn alignItemsStretch">
                    <div className="displayFlex justifyContentEnd">
                        {showSuggestions
                            ?
                            <></>
                            :
                            <div className="hoverHand marginTopS marginBottomS paddingM" onClick={() => { setShowSuggestions(true) }}>
                                {t('chatBotPage.needMoreIdeas')}
                            </div>
                        }
                    </div>
                    <div className="marginTopM displayFlex">
                        <TextField className={styles.inputTextField}
                            ref={textField}
                            styles={{
                                width: '100%',
                                fieldGroup: [
                                    { width: '100%' },
                                ]
                            }}
                            placeholder={pendingRequest ? '' : `${t('chatBotPage.canIHelp')} (⭡⭣ für Historie)`}
                            value={inputValue}
                            disabled={pendingRequest}
                            onFocus={() => setInputFocussed(true)}
                            onBlur={() => setInputFocussed(false)}
                            onChange={(event, value) => {
                                setInputValue(value);
                            }}
                            onKeyDown={keyEvent}
                        />
                        <div className='marginLeftS displayFlex gapM'>
                            {inputValue && inputValue.trim().length > 0 ?
                                <div onClick={() => submitInput(inputValue)}>
                                    <FontIcon iconName={'Send'} className="hoverHand textXL colorPrimary" ariaLabel="Voice Input" />
                                </div>
                                :
                                (transcribing
                                    ?
                                    <div onClick={() => {
                                        setTranscribing(false)
                                    }}><FontIcon iconName={'CircleStopSolid'} className="hoverHand textXL colorRed pulsing" ariaLabel="Voice Input" /></div>
                                    :
                                    <div onClick={() => {
                                        setTranscribing(true)
                                    }}><FontIcon iconName={'Microphone'} className="hoverHand textXL colorPrimary" ariaLabel="Voice Input" /></div>
                                )}
                        </div>
                        {/* <div className='inputControls displayFlex alignItemsCenter'>
                            {inputValue && inputValue.trim().length > 0 && speechController.current.state !== SpeechControllerState.transcribing && speechController.current.state !== SpeechControllerState.thinking ?
                                <div className="hoverHand width20 displayFlex justifyContentCenter alignItemsCenter" onClick={() => {
                                    submitInput(inputValue);
                                }}>
                                    <FontIcon iconName={'send'} className="textXL colorPrimary" ariaLabel="send" />
                                </div>
                                :
                                (
                                    speechController.current.state !== SpeechControllerState.thinking
                                        ?
                                        <div className={`hoverHand width20 displayFlex justifyContentCenter alignItemsCenter ${speechControllerState === SpeechControllerState.transcribing ? 'listening' : (speechControllerState === SpeechControllerState.thinking ? 'thinking' : '')}`}
                                            onClick={() => {
                                                if (speechController.current.state === SpeechControllerState.thinking) {
                                                    return;
                                                } else if (speechController.current.state === SpeechControllerState.transcribing) {
                                                    speechController.current.stopTranscribing();

                                                    textField.current.disabled = false;

                                                    textField.current.focus();
                                                } else {
                                                    speechController.current.transcribe();

                                                    textField.current.disabled = true;
                                                }
                                            }}>
                                            <FontIcon iconName={'Microphone'} className={'textXL ' + (speechControllerState !== SpeechControllerState.initialized && speechControllerState !== SpeechControllerState.listening ? suggestionIconStyles.white : 'colorPrimary')} ariaLabel="Voice Input" />
                                        </div>
                                        :
                                        <></>
                                )
                            }
                        </div> */}
                    </div>
                </div>
                <Dialog
                    hidden={hideDialog}
                    onDismiss={toggleHideDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: clientActionTitle(skillAgentResponse),
                        closeButtonAriaLabel: 'Close',
                        minWidth: 600
                    }}>
                    {skillAgentResponse && skillAgentResponse.skill ?
                        <>
                            {skillAgentResponse.message}
                            {(() => {
                                switch (skillAgentResponse.skill.id) {
                                    case 'clientAction/wayfinding': return <img src="./images/navigation.png" alt="navigation" className={styles.dummyUX} />
                                    case 'clientAction/showDetails': return <img src="./images/room.png" alt="room" className={styles.dummyUX} />
                                    default: return <></>
                                }
                            })()}</>
                        :
                        <></>}
                    <DialogFooter>
                        <PrimaryButton onClick={toggleHideDialog} text="Close" />
                    </DialogFooter>
                </Dialog>
            </div>
        </div >);
};