import { ActionButton, DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import '../App.sass';
import { Text } from '@fluentui/react/lib/Text';
import Section from '../Section';
import { useTranslation } from "react-i18next";
import { pdf, PDFDownloadLink, Document, Page, Image, Text as PDFText, View, StyleSheet, Font } from '@react-pdf/renderer';
import { pdfStyles } from '../pdf/PDF';
import { useEffect, useState } from 'react';
import { useAdministrationAPI } from '../AdministrationAPI';
import { authenticationAtom } from '../State';
import { useAtom } from 'jotai';
import { Fragment } from "react/jsx-runtime";
import Dialog, { DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { userAPI } from '../UserAPI';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { Customer, Invoice, InvoiceStatus } from "@marc.gille-sepehri/tri-model";
import DataTable from 'react-data-table-component';
import { dataTableStyles } from '../styles';
import dayjs from "dayjs";

interface Properties {
    processError: (error: any) => void;
}

const mergeStyles = (...styles: any[]) => {
    let result = {};

    styles.forEach((style: any) => {

        result = { ...result, ...style };
    });

    return result;
}

const PDFInvoice = ({ invoice, customer }: { invoice: Invoice, customer: Customer }) => {
    const styles = StyleSheet.create({
        logo: {
            width: 70,
        },
        marginTopL: {
            marginTop: '20px',
        },
        header1: {
            marginTop: '20px',
            marginBottom: '20px',
            fontSize: 16,
            fontWeight: 'bold',
            fontFamily: 'Lato Bold',
        },
        normal: {
            fontSize: 12,
            fontFamily: 'Lato',
            color: 'black',
        },
        textAlignRight: {
            textAlign: 'right',
        },
        bold: {
            fontSize: 12,
            fontWeight: 'bold',
            fontFamily: 'Lato Bold',
            color: 'black',
        },
        tableContainer: {
            flexDirection: "row",
            flexWrap: "wrap",
            marginBottom: 16,
        },
        row: {
            flexDirection: 'row',
            width: '100%',
        },
        tableHeaderRow: {
            flexDirection: 'row',
            width: '100%',
            borderBottom: "1px solid grey"
        },
        itemGroupRow: {
            flexDirection: 'row',
            width: '100%',
            marginTop: "10px",
            marginBottom: "10px",
            paddingLeft: '5px',
            paddingRight: '5px',
        },
        totalRow: {
            flexDirection: 'row',
            width: '100%',
            borderTop: "1px solid grey"
        },
        footerRow: {
            flexDirection: 'row',
            width: '100%',
            borderBottom: "1px solid grey"
        },
        width10: {
            width: '10%',
        },
        width15: {
            width: '15%',
        },
        width20: {
            width: '20%',
        },
        width30: {
            width: '30%',
        },
        width40: {
            width: '40%',
        },
        width50: {
            width: '50%',
        },
        width70: {
            width: '70%',
        },
        column50: {
            width: '50%',
        },
        width85: {
            width: '85%',
        },
        width90: {
            width: '90%',
        },
        nameCell: {
            width: '30%',
        },
        valueCell: {
            width: '30%',
        },
        cell: {
            paddingLeft: '5px',
            paddingRight: '5px',
            paddingTop: '2px',
            paddingBottom: '2px',
        },
        columnEnd: {
        },
        address: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        }
    });

    const currencyFormat = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });
    const numberFormat = new Intl.NumberFormat('de-DE');
    const amountFormat = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 4, maximumFractionDigits: 4 });

    let pos = 0;

    try {
        return <Document>
            <Page size="A4" style={pdfStyles.page}>
                <View style={styles.row}>
                    <View style={styles.width70}>
                        <Image style={styles.logo} src={'http://localhost:3000/logo.png'}></Image>
                    </View>
                    <View style={styles.width30}>
                        <View style={styles.columnEnd}>
                            <View style={styles.address}>
                                <PDFText style={styles.bold}>The Real Insight UG</PDFText>
                                <PDFText style={styles.normal}>Am Wurstgraben 11</PDFText>
                                <PDFText style={styles.normal}>61290 Eschhofen</PDFText>
                                <PDFText style={styles.normal}>Steuernummer 11</PDFText>
                                <PDFText style={styles.normal}>UStId 4711</PDFText>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={{ ...styles.row, ...styles.marginTopL }}>
                    <PDFText style={styles.bold}>{customer.name}</PDFText>
                </View>
                <View style={styles.row}>
                    <PDFText style={styles.normal}>{invoice.billingAddress.street} {invoice.billingAddress.streetNumber}</PDFText>
                </View>
                <View style={styles.row}>
                    <PDFText style={styles.normal}>{invoice.billingAddress.postalCode} {invoice.billingAddress.city}</PDFText>
                </View>
                <View style={styles.row}>
                    <PDFText style={styles.normal}>UStId 2314</PDFText>
                </View>
                <PDFText style={styles.header1}>Rechnung {invoice.periodFromDate.getMonth() + 1}/{invoice.periodFromDate.getFullYear()}</PDFText>
                <View style={styles.row}>
                    <View style={styles.width30}>
                        <PDFText style={styles.bold}>
                            Rechnungsnummer:
                        </PDFText>
                    </View>
                    <View style={styles.width30}>
                        <PDFText style={styles.normal}>
                            {invoice.id}
                        </PDFText>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.width30}>
                        <PDFText style={styles.bold}>
                            Kundennummer:
                        </PDFText>
                    </View>
                    <View style={styles.valueCell}>
                        <PDFText style={styles.normal}>
                            {customer.id}
                        </PDFText>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.width30}>
                        <PDFText style={styles.bold}>
                            Abrechnungszeitraum:
                        </PDFText>
                    </View>
                    <View style={styles.width30}>
                        <PDFText style={styles.normal}>
                            {invoice.periodFromDate.toLocaleDateString()} - {invoice.periodToDate.toLocaleDateString()}
                        </PDFText>
                    </View>
                </View>
                <View style={{ ...styles.tableContainer, ...styles.marginTopL }}>
                    <View style={styles.tableHeaderRow}>
                        <View style={mergeStyles(styles.width10, styles.cell)}><PDFText style={mergeStyles(styles.bold, styles.textAlignRight)}>Pos.</PDFText></View>
                        <View style={mergeStyles(styles.width40, styles.cell)}><PDFText style={styles.bold}>Beschreibung</PDFText></View>
                        <View style={mergeStyles(styles.width15, styles.cell)}><PDFText style={mergeStyles(styles.bold, styles.textAlignRight)}>Menge</PDFText></View>
                        <View style={mergeStyles(styles.width20, styles.cell)}><PDFText style={mergeStyles(styles.bold, styles.textAlignRight)}>Betrag</PDFText></View>
                        <View style={mergeStyles(styles.width15, styles.cell)}><PDFText style={mergeStyles(styles.bold, styles.textAlignRight)}>Gesamt</PDFText></View>
                    </View>
                    <Fragment>
                        {invoice.itemGroups.map((itemGroup: any) => {
                            return <Fragment>
                                <View style={styles.itemGroupRow}>
                                    <PDFText style={styles.bold}>{itemGroup.name}</PDFText>
                                </View>
                                {itemGroup.items.map((item: any) => {
                                    ++pos;

                                    return <View style={pdfStyles.row}>
                                        <View style={mergeStyles(styles.width10, styles.cell)}><PDFText style={mergeStyles(styles.normal, styles.textAlignRight)}>{pos}</PDFText></View>
                                        <View style={mergeStyles(styles.width40, styles.cell)}><PDFText style={styles.normal}>{item.description}</PDFText></View>
                                        <View style={mergeStyles(styles.width15, styles.cell)}><PDFText style={mergeStyles(styles.normal, styles.textAlignRight)}>{numberFormat.format(item.volume)}</PDFText></View>
                                        <View style={mergeStyles(styles.width20, styles.cell)}><PDFText style={mergeStyles(styles.normal, styles.textAlignRight)}>{amountFormat.format(item.amount)}</PDFText></View>
                                        <View style={mergeStyles(styles.width15, styles.cell)}><PDFText style={mergeStyles(styles.normal, styles.textAlignRight)}>{currencyFormat.format(item.totalAmount)}</PDFText></View>
                                    </View>;
                                })}
                            </Fragment>;
                        })}
                    </Fragment>
                    <View style={styles.totalRow}>
                        <View style={mergeStyles(styles.width85, styles.cell)}><PDFText style={styles.bold}>Gesamtbetrag (netto)</PDFText></View>
                        <View style={mergeStyles(styles.width15, styles.cell)}><PDFText style={mergeStyles(styles.bold, styles.textAlignRight)}>{currencyFormat.format(invoice.subtotal())}</PDFText></View>
                    </View>
                    <View style={styles.row}>
                        <View style={mergeStyles(styles.width85, styles.cell)}><PDFText style={styles.normal}>MwSt.</PDFText></View>
                        <View style={mergeStyles(styles.width15, styles.cell)}><PDFText style={mergeStyles(styles.normal, styles.textAlignRight)}>{currencyFormat.format(invoice.grandTotal(0.19) - invoice.subtotal())}</PDFText></View>
                    </View>
                    <View style={styles.footerRow}>
                        <View style={mergeStyles(styles.width85, styles.cell)}><PDFText style={styles.bold}>Gesamtbetrag (brutto)</PDFText></View>
                        <View style={mergeStyles(styles.width15, styles.cell)}><PDFText style={mergeStyles(styles.bold, styles.textAlignRight)}>{currencyFormat.format(invoice.grandTotal(0.19))}</PDFText></View>
                    </View>
                </View>
                <View style={mergeStyles(styles.row, styles.marginTopL)}>
                    <PDFText style={styles.normal}>Zahlbar innerhalb von 14 Tagen ohne Abzug.</PDFText>
                </View>
                <View style={mergeStyles(styles.row, styles.marginTopL)}>
                    <PDFText style={styles.normal}>Bankverbindung: Konto IBAN 47011100001, Sparkasse Limburg</PDFText>
                </View>
            </Page>
        </Document >
    } catch (error) {
        console.log(error);

        return <Document><Page><PDFText>Error in Invoice Creation.</PDFText></Page></Document>
    }
};

export default function BillingPanel(properties: Properties) {
    const { t } = useTranslation();
    const [authentication] = useAtom(authenticationAtom) as any;
    const { getInvoiceForecast, getInvoices, currentlyActiveOrders } = useAdministrationAPI();
    const [customer, setCustomer] = useState() as any;
    const [currentInvoice, setCurrentInvoice] = useState() as any;
    const [currentInvoicePDF, setCurrentInvoicePDF] = useState() as any;
    const currencyFormat = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });
    const [planDialogOpen, setPlanDialogOpen] = useState(false);
    const [orders, setOrders] = useState([]) as any;
    const [invoices, setInvoices] = useState([]) as any;
    const [plans, setPlans] = useState() as any;
    const [portalAndAppUsagePlan, setPortalAndAppUsagePlan] = useState() as any;
    const [privateDataIngestionPlan, setPrivateDataIngestionPlan] = useState() as any;
    const [newPortalAndAppUsagePlan, setNewPortalAndAppUsagePlan] = useState() as any;
    const [newPrivateDataIngestionPlan, setNewPrivateDataIngestionPlan] = useState() as any;

    const portalAndAppUsageItems = (): IDropdownOption[] => plans && plans.portalAndAppUsage ? plans.portalAndAppUsage.map((plan: any) => {
        return {
            key: plan.id,
            text: plan.name
        };
    }) : [];

    const privateDataIngestionItems = (): IDropdownOption[] => plans && plans.privateDataIngestion ? [{ key: null, text: t('global.none') }, ...plans.privateDataIngestion.map((plan: any) => {
        return {
            key: plan.id,
            text: plan.name
        };
    })] : [];

    const planSelectionComplete = (): boolean => {
        return portalAndAppUsagePlan !== undefined;
    }

    useEffect(() => {
        const call = async () => {
            const newCustomer = await userAPI.getCustomer(authentication.customer);

            newCustomer.address = newCustomer.address || {};

            setCustomer(newCustomer);

            if (currentInvoice) {
                setCurrentInvoicePDF(<PDFInvoice invoice={currentInvoice} customer={customer}></PDFInvoice>);
            }
        };

        try {
            call();
        } catch (error: any) {
            properties.processError(error);
        }
    }, []);

    useEffect(() => {
        const call = async () => {
            const today = new Date();
            const periodFromDate = dayjs(today).startOf('month').toDate();
            const periodToDate = dayjs(today).endOf('month').toDate();
            const invoice = await getInvoiceForecast(authentication.customer,
                periodFromDate, periodToDate);

            setCurrentInvoice(invoice);

            if (customer && invoice) {
                setCurrentInvoicePDF(<PDFInvoice invoice={invoice} customer={customer}></PDFInvoice>);
            }
        }

        try {
            call();
        } catch (error: any) {
            properties.processError(error);
        }
    }, []);

    useEffect(() => {
        const call = async () => {
            setInvoices((await getInvoices(authentication.customer, new Date('2024-05-01T00:00:00'), new Date()))
                .sort((a: Invoice, b: Invoice) => b.periodFromDate.getTime() - a.periodFromDate.getTime()));
        }

        try {
            call();
        } catch (error: any) {
            properties.processError(error);
        }
    }, []);

    const findPlan = (plans: any, id: string) => plans.find((plan: any) => plan.id === id);

    useEffect(() => {
        const call = async () => {
            const plans = await userAPI.getPlans();
            const orders = await currentlyActiveOrders(authentication.customer);

            orders.forEach((order: any) => {
                if (order.service === "portalAndAppUsage") {
                    setPortalAndAppUsagePlan(findPlan(plans.portalAndAppUsage, order.plan));
                } else if (order.service === "privateDataIngestion") {
                    setPrivateDataIngestionPlan(findPlan(plans.privateDataIngestion, order.plan));
                }
            });

            setOrders(orders);
            setPlans(plans);
        };

        call();
    }, []);

    const togglePlanDialogOpen = () => {
        setPlanDialogOpen(!planDialogOpen);
    }

    const downloadFile = (blob: Blob, fileName: string) => {
        const link = document.createElement('a');

        link.href = URL.createObjectURL(blob);
        link.download = fileName;

        // Some browser needs the anchor to be in the doc

        document.body.append(link);
        link.click();
        link.remove();

        // In case the Blob uses a lot of memory

        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    };
    
    const columns = [{
        name: t('billingPanel.paymentStatusColumn'),
        width: '150px',
        cell: (invoice: Invoice) => (
            <div className="width50 paddingS displayFlex justifyContentCenter">
                <div className={(invoice.status === InvoiceStatus.paid ? 'colorGreen' : 'colorRed') + ' fontWeightBold textM'}>{invoice.status === InvoiceStatus.paid ? 'Bezahlt' : 'Offen'}</div>
            </div>
        ),
    }, {
        name: t('billingPanel.invoiceDateColumn'),
        width: '150px',
        right: true,
        cell: (invoice: Invoice) => (
            <Text variant="medium">{invoice.creationDate.toLocaleDateString()}</Text>
        ),
    }, {
        name: t('billingPanel.invoicePeriodColumn'),
        width: '200px',
        cell: (invoice: Invoice) => (
            <Text variant="medium">{invoice.periodFromDate.toLocaleDateString()} - {new Date(invoice.periodToDate).toLocaleDateString()}</Text>
        ),
    }, {
        name: t('billingPanel.amountColumn'),
        width: '150px',
        right: true,
        cell: (invoice: Invoice) => (
            <Text variant="medium">{currencyFormat.format(invoice.subtotal())}</Text>
        ),
    }, {
        name: t('billingPanel.downloadColumn'),
        cell: (invoice: any) => (
            <ActionButton iconProps={{ iconName: 'Download' }} onClick={async () => {
                const blob = await pdf(<PDFInvoice invoice={invoice} customer={customer}></PDFInvoice>).toBlob();

                console.log(blob);

                downloadFile(blob, `Rechnung ${invoice.id}`);
            }}>
            </ActionButton>
        ),
    },
    ];

    return <><div>
        <div className='displayFlex gapL'>
            <div className="displayFlex flexDirectionColumn width800 gapL">
                <Section title="Aktueller Abrechnungszeitraum">
                    {currentInvoice
                        ?
                        <>
                        <div className='displayFlex alignItemsCenter gapS'>
                            <Text className="fontWeightBold" variant="medium">Betrag</Text>
                            <Text variant="xxLarge">{currencyFormat.format(currentInvoice.grandTotal(0.19))}</Text>
                            </div>
                            <div className='marginTopL displayFlex'>
                                {invoices && invoices.length > 0
                                    ?
                                    <div className='flexGrow1'>
                                        <div>
                                            <Text className="fontWeightBold" variant="medium">Letzte Rechnung bis</Text>
                                        </div>
                                        <div>
                                            <Text variant="medium">{invoices[0].periodToDate.toLocaleDateString()}</Text>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            <div className='marginTopL displayFlex justifyContentEnd'>
                                {currentInvoicePDF ?
                                    <PDFDownloadLink document={currentInvoicePDF} fileName="Abrechnung Juli 2024.pdf">
                                        {({ blob, url, loading, error }) =>
                                            loading
                                                ?
                                                'Loading document...'
                                                :
                                                <PrimaryButton iconProps={{ iconName: 'Download' }}>
                                                    Rechnungsvorschau
                                                </PrimaryButton>
                                        }
                                    </PDFDownloadLink>
                                    :
                                    <></>
                                }
                            </div>
                        </>
                        :
                        <></>}
                </Section>
                <Section title={t('billingPanel.invoices')}>
                    <DataTable
                        columns={columns}
                        data={invoices}
                        customStyles={dataTableStyles}
                        pagination
                    />
                </Section>
            </div>
            <div className='displayFlex flexDirectionColumn gapL width30vw'>
                <Section title="Lizenzen">
                    <div className="displayFlex flexDirectionColumn justifyContentCenter">
                        <Text>Deine aktuelle Lizenz sind</Text>
                    </div>
                    <ul>
                        <li>
                            <Text><div className='fontWeightBold'>Portal & App-Nutzung</div> Basis 3 Nutzende</Text>
                        </li>
                        <li>
                            <Text><div className='fontWeightBold'>Verwaltung eigener Daten</div> Abrechnung nach Grundgebühr und Anzahl Datensätzen</Text>
                        </li>
                    </ul>
                    <div className='marginTopL displayFlex justifyContentEnd'>
                        <PrimaryButton text="Ändern" onClick={() => { togglePlanDialogOpen() }} />
                    </div>
                </Section>
                <Section title="Zahlung">
                    <div><Text>{t('billingPanel.noPaymentMethodYet')}</Text></div>
                    <div className='marginTopL displayFlex justifyContentEnd'>
                        <PrimaryButton text="Ergänzen" onClick={() => { }} />
                    </div>
                </Section>
                {/* <Section title="Abrechnungsprofil">
                    <div className='displayFlex justifyContentEnd'>
                        <PrimaryButton text="Ändern" onClick={() => { }} />
                    </div>
                    <div className='marginTopL'>
                        <Text variant="medium">Kein Abrechnungsprofil hinterlegt.</Text>
                    </div>
                </Section> */}
            </div>
        </div>
    </div>
        <Dialog hidden={!planDialogOpen}
            onDismiss={togglePlanDialogOpen}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('settingsPanel.billingPanel.licenses'),
            }}
            modalProps={{
                isBlocking: true,
            }}>
            <Dropdown
                placeholder="Select an option"
                label="Nutzung von Portal und App"
                options={portalAndAppUsageItems()}
                styles={{
                    dropdown: { width: 400 },
                    dropdownOptionText: { overflow: 'visible', whiteSpace: 'normal' },
                    dropdownItem: { height: 'auto' },
                }}
                selectedKey={portalAndAppUsagePlan ? portalAndAppUsagePlan.id : undefined}
                onChange={(event: any, item: any) => {
                    if (plans.portalAndAppUsage) {
                        setPortalAndAppUsagePlan(plans.portalAndAppUsage.find((plan: any) => plan.id === item.key));
                    }
                }}
            />
            <div className="marginTopS height50">
                <Text variant='small'>{portalAndAppUsagePlan ? portalAndAppUsagePlan.description : ''}</Text>
            </div>
            <Dropdown
                placeholder="Select an option"
                label="Management privater Daten"
                options={privateDataIngestionItems()}
                styles={{
                    dropdown: { width: 400 },
                    dropdownOptionText: { overflow: 'visible', whiteSpace: 'normal' },
                    dropdownItem: { height: 'auto' },
                }}
                selectedKey={privateDataIngestionPlan ? privateDataIngestionPlan.id : undefined}
                onChange={(event: any, item: any) => {
                    if (plans.privateDataIngestion) {
                        setPrivateDataIngestionPlan(plans.privateDataIngestion.find((plan: any) => plan.id === item.key));
                    }
                }}
            />
            <div className="marginTopS  height50">
                <Text variant='small'>{privateDataIngestionPlan ? privateDataIngestionPlan.description : ''}</Text>
            </div>
            <DialogFooter className="marginTopXXL">
                <PrimaryButton disabled={!planSelectionComplete()} text={t('global.change')} onClick={async () => {
                }} />
                <DefaultButton onClick={togglePlanDialogOpen} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog></>;
}