import { Address, AndFilter, Comparison, EntityQuery, Query, or, is } from "@marc.gille-sepehri/tri-model";
import { userAPI } from "../UserAPI";
import { useEffect, useRef, useState } from "react";
import { TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps } from '@fluentui/react/lib/Pickers';
import { ContextualMenu, SearchBox } from "@fluentui/react";

interface Properties {
    setAddressFilter?: (addressFilter: any) => void
}

export default function AddressPicker(properties: Properties) {
    const linkRef = useRef(null);
    const [filter, setFilter] = useState('');
    const [addresses, setAddresses] = useState([]) as any;
    const [addressFilter, setAddressFilter] = useState() as any;
    const [menuOpen, setMenuOpen] = useState([]) as any;

    const groupBy = (addresses: any) => {
        const map = addresses.reduce(function (map: any, address: any) {
            (map[`${address.street}-${address.city}`] = map[`${address.street}-${address.city}`] || []).push(address);
            return map;
        }, {});

        return Object.values(map).map((group: any) => {
            //logDebug(group);

            return {
                street: group[0].street,
                city: group[0].city,
                streetNumber: group.length === 1 ? group[0].streetNumber : undefined,
                group,
            }
        });
    };

    useEffect(() => {
        const debounce = setTimeout(async () => {
            console.log(filter);

            if (filter && filter.trim().length > 2) {
                // TODO Pushed to server as canned service - or reuse existing

                // const addresses = await userAPI.query(new Query(new EntityQuery(Address.type, [], undefined,
                //     or(is('city').equalRegExp(`(?i)${filter}.*`),
                //         is('street').equalRegExp(`(?i)${filter}.*`)))));
                const addresses = await userAPI.query(new Query(new EntityQuery(Address.type, [], undefined,
                    is('street').equalRegExp(`(?i)${filter}.*`))));

                const groups = groupBy(addresses);

                setAddresses(groups);
                setMenuOpen(true);
            }
        }, 1500);

        return () => clearTimeout(debounce)
    }, [filter])

    const filterAddresses = async (filter: string) => {
        setFilter(filter);
        setAddressFilter(null);
        setAddresses([]);

        if (properties.setAddressFilter) {
            properties.setAddressFilter(null);
        }
    };

    return <div>
        <SearchBox defaultValue="Straße oder Ort eingeben" value={addressFilter ? `${addressFilter.street}, ${addressFilter.city}` : filter} ref={linkRef} onChange={(event: any, filter: any) => filterAddresses(filter)}
            styles={{ root: { width: 450 } }}></SearchBox>
        <ContextualMenu
            items={addresses.map((address: Address) => {
                return {
                    key: `${address.street}, ${address.city}`, text: `${address.street}, ${address.city}`, onClick: () => {
                        setAddressFilter(address);
                        setMenuOpen(false);


                        if (properties.setAddressFilter) {
                            properties.setAddressFilter(address);
                        }
                    }
                };
            })}
            hidden={!menuOpen}
            target={linkRef}
            onItemClick={() => setMenuOpen(false)}
            onDismiss={() => setMenuOpen(false)}
        />
    </div>
}