import { CommandBar, ICommandBarItemProps } from "@fluentui/react";
import { useEffect, useState } from "react";
import LeaseBuildingProperties from "./LeaseBuildingProperties";
import GeneralBuildingProperties from "./GeneralBuildingProperties";
import EnergyManagementProperties from "./EnergyManagementProperties";
import UtilizationProperties from "./UtilizationProperties";
import { useTranslation } from 'react-i18next';
import TechnicalEquipmentProperties from "./TechnicalEquipmentProperties";
import DecarbonizationProperties from "./plot-of-land/DecarbonisationProperties";
import SocioDemographicsProperties from "./plot-of-land/SocioDemographicsProperties";
import PoIProperties from "./plot-of-land/PoIProperties";
import Environment3DProperties from "./building/Environment3DProperties";
import PVPotentialProperties from "./building/PVPotentialProperties";
import FinancialProperties from "./building/FinancialProperties";

class Properties {
  building: any;
  poisChanged?: (pois: any[]) => void
}

export default function BuildingProperties(properties: Properties) {
  const { t } = useTranslation();
  const [tab, setTab] = useState('general');

  const _items: ICommandBarItemProps[] = [
    {
      key: 'general',
      text: t('buildingProperties.general'),
      iconProps: { iconName: 'Info' },
      onClick: () => setTab('general'),
    }, {
      key: 'neighborhood',
      text: '3D-View',
      iconProps: { iconName: 'SchoolDataSyncLogo' },
      onClick: () => setTab('neighborhood'),
    }, {
      key: 'poi',
      text: 'In der Nähe',
      iconProps: { iconName: 'MapPin' },
      onClick: () => setTab('poi'),
    }];

  const _overflowItems: ICommandBarItemProps[] = [{
    key: 'sociodemographics',
    text: 'Soziodemographie',
    iconProps: { iconName: 'SchoolDataSyncLogo' },
    onClick: () => setTab('sociodemographics'),
  },
  {
    key: 'crime',
    text: 'Kriminalität',
    iconProps: { iconName: 'EndPointSolid' },
    onClick: () => setTab('crime'),
  },
  {
    key: 'legal',
    text: t('buildingProperties.legal'),
    iconProps: { iconName: 'Compare' },
    onClick: () => setTab('legal'),
  },
  {
    key: 'financial',
    text: t('buildingProperties.financial'),
    iconProps: { iconName: 'Money' },
    onClick: () => setTab('financial'),
  },
  {
    key: 'lease',
    text: t('buildingProperties.lease'),
    iconProps: { iconName: 'Page' },
    onClick: () => setTab('lease'),
  },
  {
    key: 'technicalEquipment',
    text: t('buildingProperties.technicalEquipment'),
    iconProps: { iconName: 'ConstructionCone' },
    onClick: () => setTab('technicalEquipment'),
  },
  {
    key: 'move',
    text: t('buildingProperties.maintenance'),
    onClick: () => setTab('maintenance'),
    iconProps: { iconName: 'Repair' }
  },
  {
    key: 'utilization',
    text: t('buildingProperties.utilization'),
    onClick: () => setTab('utilization'),
    iconProps: { iconName: 'PeopleRepeat' }
  },
  {
    key: 'energyManagement',
    text: t('buildingProperties.energyManagement'),
    iconProps: { iconName: 'PlugDisconnected' },
    onClick: () => setTab('energyManagement'),
  },
  {
    key: 'pvPotential',
    text: 'PV Potenzial',
    iconProps: { iconName: 'PlugDisconnected' },
    onClick: () => setTab('pvPotential'),
  }, {
    key: 'decarbonisation',
    text: 'Dekarbonisierung',
    iconProps: { iconName: 'Lightbulb' },
    onClick: () => setTab('decarbonisation'),
  }];

  useEffect(() => {
    if (properties.poisChanged) {
      properties.poisChanged([]);
    }
  }, [tab]);

  return (
    <div>
      <div className="">
        <CommandBar
          styles={{ root: { padding: '0px', } }}
          items={_items}
          overflowItems={_overflowItems}
          overflowButtonProps={{ ariaLabel: 'More commands' }}
          ariaLabel="Inbox actions"
          primaryGroupAriaLabel="Email actions"
          farItemsGroupAriaLabel="More actions"
        />
      </div>
      <div className="flex1 displayFlex flexDirectionColumn overflowYScroll">
        {(() => {
          switch (tab) {
            case 'general': return <GeneralBuildingProperties building={properties.building}></GeneralBuildingProperties>
            case 'legal': return <div>
              <div className="">
                <div className="headerL">Eigentümer</div>
                <div className='marginTopM'><div className="textM">{t('global.noInformationAvailable')}</div></div>
              </div>
              <div className="">
                <div className="marginTopM headerL">Eigentümergemeinschaften</div>
                <div className='marginTopM'><div className="textM">{t('global.noInformationAvailable')}</div></div>
              </div>
            </div>
            case 'neighborhood': return <div className='marginTopL'><Environment3DProperties object={properties.building}></Environment3DProperties></div>;
            case 'poi': return <PoIProperties object={properties.building} poisChanged={properties.poisChanged}></PoIProperties>
            case 'energyManagement': return <EnergyManagementProperties building={properties.building}></EnergyManagementProperties>
            case 'pvPotential': return <PVPotentialProperties building={properties.building}></PVPotentialProperties>
            case 'decarbonisation': return <div>
              <DecarbonizationProperties object={properties.building}></DecarbonizationProperties>
            </div>
            case 'sociodemographics': return <SocioDemographicsProperties object={properties.building}></SocioDemographicsProperties>
            case 'lease':
              return <LeaseBuildingProperties></LeaseBuildingProperties>;
            case 'utilization': return <UtilizationProperties building={properties.building}></UtilizationProperties>;
            case 'technicalEquipment': return <TechnicalEquipmentProperties building={properties.building}></TechnicalEquipmentProperties>;
            case 'maintenance': return <>
              <div className='marginTopM'>{t('global.noInformationAvailable')}</div>
            </>;
            case 'crime': return <div>
              <div className="marginTopL">
                <div className="headerL">Kriminalität</div>
              </div>
              <div className="marginTopL displayFlex alignItemsBaseline gapS">
                <div className="divXXL fontWeightBold colorPrimaryLight">7.200</div><div className="divXL colorPrimaryLight">Straftaten/100.000 Einwohner</div>
              </div>
              <div className="marginTopM displayFlex alignItemsBaseline gapS">
                <div className="divXL fontWeightBold colorPrimary">3%</div><div className="divL colorPrimary">über Bundesdurchschnitt</div>
              </div>
              <div className="marginTopS displayFlex alignItemsBaseline gapS">
                <div className="divXL fontWeightBold colorPrimary">5%</div><div className="divL colorPrimary">über Durchschnitt {properties.building.address.city}</div>
              </div>
            </div>
            case 'financial':
            default: return <FinancialProperties building={properties.building}></FinancialProperties>;
          }
        })()}
      </div>
    </div >
  );
}
