import Header from "./Header";
import { Pivot, PivotItem } from "@fluentui/react";
import UserManagementPanel from "./settings/UserManagementPanel";
import UserProfilePanel from "./settings/UserProfilePanel";
import SecurityPanel from "./settings/SecurityPanel";
import BillingPanel from "./settings/BillingPanel";
import CompanyPanel from "./settings/CompanyPanel";
import { useTranslation } from "react-i18next";
import APIPanel from "./settings/APIPanel";
import { useAtom } from "jotai";
import { authenticationAtom } from './State';
import { useLocation } from "react-router-dom";
import { useMemo, useState } from "react";
import { MessageBox, useMessagBox } from "./components/MessageBox";
import DataManagementPanel from "./settings/DataManagementPanel";
import ProductManagementPanel from "./settings/ProductManagementPanel";
import AlertManagementPanel from "./settings/AlertManagementPanel";
import OperationsDashboard from "./dashboards/OperationsDashoard";
import ProjectPanel from "./settings/ProjectPanel";
import SideBar, { SideBarItem } from "./components/SideBar";
import ContentPanel from "./components/ContentPanel";

export default function SettingsPage() {
    const { t } = useTranslation();
    const [authentication] = useAtom(authenticationAtom) as any;
    const { search } = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const [selectedTab, setSelectedTab] = useState(searchParams.get("tab") || '0');
    const { processError, messageBox } = useMessagBox();

    const sideBarItems: SideBarItem[] = [
        { key: 'userProfile', text: t('settingsPanel.userProfile'), iconName: "UserOptional" },
        { key: 'securityProfile', text: t('settingsPanel.securityProfile'), iconName: "PageLock" },
        { key: 'companyProfile', text: t('settingsPanel.companyProfile'), iconName: "CompanyDirectory" },
        { key: 'teamManagement', text: t('settingsPanel.teamManagement'), iconName: "People" },
        { key: 'billing', text: t('settingsPanel.billing'), iconName: "Money" },
        { key: 'api', text: t('settingsPanel.api'), iconName: "AzureAPIManagement" },
        { key: 'projects', text: 'Projects', iconName: "ProjectCollection" },
        { key: 'productManagement', text: 'Product Management', iconName: "Product" },
        { key: 'alerts', text: 'Alerts', iconName: "AlertSettings" },
        { key: 'operationalState', text: 'Operating State', iconName: "SyncStatus" },
        { key: 'dataManagement', text: 'Data Management', iconName: "Globe" },
    ];

    return <div className="settingsPage positionRelative">
        <Header showHome={true} title={t('settingsPanel.settings')}></Header>
        <div className="bodyPanel">
            <SideBar items={sideBarItems} setItem={setSelectedTab}></SideBar>
            <ContentPanel>
                <MessageBox messageBox={messageBox} ></MessageBox>
                {authentication && authentication.roles && authentication.roles.indexOf('administrator') >= 0
                    ?
                    <div>
                        {(() => {
                            switch (selectedTab) {
                                case 'userProfile': return <UserProfilePanel processError={processError}></UserProfilePanel>;
                                case 'securityProfile': return <SecurityPanel processError={processError}></SecurityPanel>;
                                case 'companyProfile': return <CompanyPanel processError={processError}></CompanyPanel>;
                                case 'teamManagement': return <UserManagementPanel processError={processError}></UserManagementPanel>;
                                case 'billing': return <BillingPanel processError={processError}></BillingPanel>;
                                case 'api': return <APIPanel processError={processError}></APIPanel>;
                                case 'projects': return <ProjectPanel processError={processError}></ProjectPanel>;
                                case 'productManagement': return <ProductManagementPanel processError={processError}></ProductManagementPanel>;
                                case 'alerts': return <AlertManagementPanel processError={processError}></AlertManagementPanel>;
                                case 'operationalState': return <OperationsDashboard processError={processError}></OperationsDashboard>;
                                case 'dataManagement': return <DataManagementPanel processError={processError}></DataManagementPanel>;
                            }
                        })()}
                    </div>
                    :
                    <>
                        <Pivot aria-label="">
                            <PivotItem id="userProfile" headerText={t('settingsPanel.userProfile')} itemIcon="UserOptional">
                                <div className="paddingM">
                                    <UserProfilePanel processError={processError}></UserProfilePanel>
                                </div>
                            </PivotItem>
                            <PivotItem id="securityProfile" headerText={t('settingsPanel.securityProfile')}>
                                <div className="paddingM">
                                    <SecurityPanel processError={processError}></SecurityPanel>
                                </div>
                            </PivotItem>
                        </Pivot>
                    </>
                }
            </ContentPanel>
        </div>
    </div>;
}