import '../App.sass';
import { useEffect, useState } from 'react';
import { ActionButton, DefaultButton, PrimaryButton, Text, TextField } from '@fluentui/react';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { userAPI } from '../UserAPI';
import Section from '../Section';
import { useAtom } from "jotai";
import { authenticationAtom } from '../State';
import { Checkbox } from '@fluentui/react-components';
import { useAdministrationAPI } from '../AdministrationAPI';
import Dialog, { DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import { User } from '@marc.gille-sepehri/tri-model';
import { dataTableStyles } from '../styles';

interface Properties {
    processError: (error: any) => void;
}

export default function UserManagementPanel(properties: Properties) {
    const { t } = useTranslation();
    const { updateUser, createUser } = useAdministrationAPI();
    const [authentication] = useAtom(authenticationAtom) as any;
    const [users, setUsers] = useState([]);
    const [userDialogOpen, setUserDialogOpen] = useState(false);
    const [editedUser, setEditedUser] = useState({}) as any;

    const toggleUserDialogOpen = () => {
        setUserDialogOpen(!userDialogOpen);
    }

    const columns = [
        {
            name: t('settingsPanel.userManagementPanel.user'),
            selector: (user: User) => user.lastName,
            width: '300px',
            cell: (user: any) => (
                <Persona {...{
                    text: `${user.firstName} ${user.lastName}`,
                    secondaryText: user.email,
                    imageInitials: `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`,
                }} text={`${user.firstName} ${user.lastName}`} size={PersonaSize.size40} />
            ),
            sortable: true,
        }, {
            name: 'Status',
        }, {
            name: t('settingsPanel.userManagementPanel.administrator'),
            cell: (user: any) => (
                <div className="displayFlex justifyContentCenter">
                    <Checkbox checked={user.roles && user.roles.indexOf('administrator') >= 0}
                        disabled={authentication.id === user.id}
                        onChange={async (e: any, value: any) => {
                            try {
                                user.roles = user.roles || [];

                                if (value && user.roles.indexOf('administrator') < 0) {
                                    user.roles.push('administrator')
                                } else {
                                    user.roles = user.roles.filter((role: string) => role !== 'administrator')
                                }

                                await updateUser(authentication.customer, user);
                            } catch (error) {
                                properties.processError(error);
                            }
                        }} />
                </div>
            )
        }, {
            name: t('settingsPanel.userManagementPanel.invalidate'),
            cell: (user: any) => (user.id !== authentication.id
                ?
                <div className="displayFlex justifyContentCenter">
                    <ActionButton iconProps={{ iconName: 'Delete' }} >
                    </ActionButton>
                </div>
                :
                <></>
            ),
        },
    ];

    useEffect(() => {
        const call = async () => {
            try {
                setUsers(await userAPI.getUsers());
            } catch (error) {
                properties.processError(error);
            }
        };

        call();
    }, []);

    return <><div className="width800">
        <Section title={t('settingsPanel.userManagementPanel.teamMembers')}>
            <ActionButton iconProps={{ iconName: 'AddFriend' }}
                onClick={toggleUserDialogOpen}>
                {t('settingsPanel.userManagementPanel.inviteUser')}
            </ActionButton>
            <DataTable
                data={users}
                columns={columns}
                customStyles={dataTableStyles}
                pagination
            />
        </Section>
    </div>
        <Dialog
            hidden={!userDialogOpen}
            onDismiss={toggleUserDialogOpen}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('settingsPanel.userManagementPanel.inviteUser'),
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <TextField label={t('settingsPanel.userManagementPanel.email')} value={editedUser.email} onChange={(e: any, email: any) => setEditedUser({ ...editedUser, email })}
                onGetErrorMessage={(value: string): string => {
                    return value && value.length > 0 ? '' : t('settingsPanel.userManagementPanel.emailNotValid');
                }}
                validateOnFocusOut />
            <TextField label={t('settingsPanel.userManagementPanel.firstName')} value={editedUser.firstName} onChange={(e: any, firstName: any) => setEditedUser({ ...editedUser, firstName })}
                onGetErrorMessage={(value: string): string => {
                    return value && value.length > 0 ? '' : t('settingsPanel.userManagementPanel.firstNameNotValid');
                }}
                validateOnFocusOut />
            <TextField label={t('settingsPanel.userManagementPanel.lastName')} value={editedUser.lastName} onChange={(e: any, lastName: any) => setEditedUser({ ...editedUser, lastName })}
                onGetErrorMessage={(value: string): string => {
                    return value && value.length > 0 ? '' : t('settingsPanel.userManagementPanel.lastNameNotValid');
                }}
                validateOnFocusOut />
            <DialogFooter className="marginTopXXL">
                <PrimaryButton text={t('settingsPanel.userManagementPanel.invite')} onClick={async () => {
                    try {
                        await createUser(authentication.customer, editedUser);

                        setUsers(await userAPI.getUsers());
                        setEditedUser({});
                        toggleUserDialogOpen();
                    } catch (error) {
                        properties.processError(error);
                    }
                }} />
                <DefaultButton onClick={() => {
                    toggleUserDialogOpen();
                }} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
    </>;
}