import { Image, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { Fragment } from "react/jsx-runtime";
import ApexCharts from 'apexcharts'
import { v4 as uuidv4 } from 'uuid';

// General reporting settings

Font.register({ family: 'Lato', src: 'http://fonts.gstatic.com/s/lato/v13/v0SdcGFAl2aezM9Vq_aFTQ.ttf' });
Font.register({ family: 'Lato Bold', src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`});

const fontFamily = 'Lato';

export const pdfStyles = StyleSheet.create({
    page: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
        fontFamily: fontFamily,
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
        fontFamily: fontFamily,
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: fontFamily,
    },
    logo: {
        marginVertical: 10,
        marginHorizontal: 10,
        width: 70,
    },
    pageHeader: {
        fontSize: 10,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },

    // Text body

    paragraph: {
        fontSize: 11,
        fontFamily: fontFamily,
        marginBottom: 16,
        color: 'black',
    },
    header1: {
        fontSize: 14,
        fontWeight: 'bold',
        marginTop: 20,
        marginBottom: 20,
        color: 'black',
        fontFamily: fontFamily,
    },

    // Layout

    centered: {
        flexDirection: "row",
        flexGrow: 1,
        justifyContent: 'center',
    },

    // Table styles

    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginBottom: 16,
    },
    tableHeaderRow: {
        flexDirection: "row",
        alignItems: "center",
        borderBottom: "1px solid grey"
    },
    tableHeaderCell: {
        fontSize: 10,
        fontWeight: 600,
        fontFamily: fontFamily,
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
    },
    cell: {
        width: "20%",
        fontSize: 10,
        fontFamily: fontFamily,
    }
});

export const PDFChart = ({ options }) => {
    const id = uuidv4();

    options.chart.id = id;
    options.chart.width = 1000;
    options.chart.height = 500;
    options.chart.animations = { enabled: false };

    try {
        const container = document.createElement("div");

        container.id = id;
        container.style.visibility = "hidden";

        document.getElementById("sandbox").appendChild(container);

        const chart = new ApexCharts(container, options);

        console.log('Chart >>>', chart);

        const provider = new Promise(async (resolve, reject) => {
            await chart.render();

            const base64 = await chart.dataURI()

            resolve(base64.imgURI);
            document.getElementById("sandbox").removeChild(container);
        });

        return <Image src={provider} style={{ width: 300, height: 150 }}></Image>;
    } catch (error) {
        console.log(error);

        return <Text>Error in Image</Text>;
    }
}

export const PDFTable = ({ data, options }) => {
    return <View style={pdfStyles.tableContainer}>
        <View style={pdfStyles.tableHeaderRow}>
            <>
                {options.headers.map((header) =>
                    <Text style={{ ...pdfStyles.tableHeaderCell, width: `"${100 / header.length}%"` }}>{header}</Text>)
                }
            </>
        </View>
        <Fragment>
            {data.map((row) =>
                <View style={pdfStyles.row}>
                    {row.map((value) => <Text style={{ ...pdfStyles.cell, width: `"${100 / row.length}%"` }}>{value}</Text>)}
                </View>
            )}
        </Fragment>
    </View>
}