import { ActionButton } from "@fluentui/react";
import { useState } from "react";
import { useCustomerTheme } from "../Theme";
import { useAtom } from "jotai";
import { themeAtom } from '../State';

export interface SideBarItem {
    key: string;
    text: String;
    iconName?: string;
    action?: () => void;
}

interface Properties {
    items: SideBarItem[];
    setItem?: (item: string) => void;
}

export default function SideBar(properties: Properties) {
    const { theme } = useCustomerTheme();
    const [selectedItem, setSelectedItem] = useState(properties.items.length > 0 ? properties.items[0] : undefined) as any;

    return <div className="sideBar borderRightNeutral">
        {properties.items.map((item: SideBarItem, index: number) => {
            return <div id={`sideBarItem${index}`} key={`sideBarItem${index}`} className={`sideBarItem`}
                onMouseEnter={() => {
                    // @ts-ignore
                    document.getElementById(`sideBarItem${index}`).style.backgroundColor = theme.primaryLight;
                }}
                onMouseLeave={() => {
                    // @ts-ignore
                    document.getElementById(`sideBarItem${index}`).style.backgroundColor = item.key === selectedItem.key ? theme.primaryLight : theme.primaryLighter;
                }}
                style={{ backgroundColor: item.key === selectedItem.key ? theme.primaryLight : 'white'}}
                onClick={() => {
                    setSelectedItem(item);

                    if (properties.setItem) {
                        properties.setItem(item.key);
                    }

                    if (item.action) {
                        item.action();
                    }
                }}>
                <ActionButton iconProps={{ iconName: item.iconName }}>
                    {item.text}
                </ActionButton>
            </div>
        })}
    </div>
}