import '../App.sass';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import Section from '../Section';
import { useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { Text } from '@fluentui/react/lib/Text';
import { settingsAtom } from '../State';
import { useAtom } from "jotai";
import { useTranslation } from 'react-i18next';
import Dialog, { DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { TextField } from '@fluentui/react/lib/TextField';
import { authenticationAtom } from '../State';
import { useAdministrationAPI } from '../AdministrationAPI';
import { userAPI } from '../UserAPI';
import { ColorPicker, Pivot, PivotItem, ThemeProvider, Toggle } from '@fluentui/react';
import tinycolor from 'tinycolor2';
import SideBar, { SideBarItem } from '../components/SideBar';
import { CustomerTheme } from '@marc.gille-sepehri/tri-model';
import { styleCalculator, useCustomerTheme } from '../Theme';

interface Properties {
    processError: (error: any) => void;
}

const testSideBarItems: SideBarItem[] = [
    { key: 'test1', text: 'Item 1', iconName: "UserOptional" },
    { key: 'test2', text: 'Item 2', iconName: "PageLock" },
    { key: 'test3', text: 'Item 3', iconName: "CityNext" },
];

export default function CompanyPanel(properties: Properties) {
    const { t } = useTranslation();
    const { theme } = useCustomerTheme();
    const { createOrUpdateCustomerTheme } = useAdministrationAPI();
    const [settings] = useAtom(settingsAtom) as any;
    const { updateCustomerLogo, updateCustomer } = useAdministrationAPI();
    const [authentication] = useAtom(authenticationAtom) as any;
    const [images, setImages] = useState([]);
    const [customer, setCustomer] = useState({ address: {} }) as any;
    const [addressDialogOpen, setAddressDialogOpen] = useState(false);
    const [editTheme, setEditTheme] = useState(null) as any;
    const [editedAddress, setEditedAddress] = useState({ address: {} }) as any;
    const [editField, setEditField] = useState(null) as any;
    const [editColor, setEditColor] = useState(null) as any;

    const onChange = async (imageList: any, addUpdateIndex: any) => {
        try {
            console.log(imageList, addUpdateIndex);

            const formData = new FormData();

            formData.append("logo", imageList[0].file);

            const result = await updateCustomerLogo(authentication.customer, formData);

            console.log('Result >>>', result);

            setCustomer({ ...customer, logoURL: result.logoURL });
            setImages([]);
        } catch (error: any) {
            properties.processError(error);
        }
    };

    const toggleAddressDialogOpen = () => {
        setEditedAddress(customer.address);
        setAddressDialogOpen(!addressDialogOpen);
    };

    useEffect(() => {
        const call = async () => {
            try {
                const newCustomer = await userAPI.getCustomer(authentication.customer);

                newCustomer.address = newCustomer.address || {};

                setCustomer(newCustomer);
            } catch (error: any) {
                properties.processError(error);
            }
        };

        call();
    }, []);

    const isStreetValid = () => {
        return editedAddress.street && editedAddress.street.trim().length > 0;
    }
    const isStreetNumberValid = () => {
        return editedAddress.streetNumber && editedAddress.streetNumber.trim().length > 0;
    }
    const isPostalCodeValid = () => {
        return editedAddress.postalCode && editedAddress.postalCode.trim().length > 0;
    }
    const isCityValid = () => {
        return editedAddress.city && editedAddress.city.trim().length > 0;
    }
    const isAddressValid = () => {
        return isStreetValid() &&
            isStreetNumberValid() &&
            isPostalCodeValid() &&
            isCityValid();
    }

    return <div>
        <div className="displayFlex flexDirectionColumn gapL width1000">
            <div className='displayFlex gapL'>
                <Section title={t('settingsPanel.companyPanel.logo')}>
                    <div className="marginTopL displayFlex flexDirectionColumn">
                        <ImageUploading
                            value={images}
                            onChange={onChange}
                            maxNumber={1}
                            dataURLKey="data_url"
                        >
                            {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                            }) => (
                                <div className="">
                                    <div className="imageArea" style={isDragging ? { border: '1px solid red' } : undefined}
                                        {...dragProps}>
                                        {imageList.length === 0
                                            ?
                                            (customer && customer.logoURL
                                                ?
                                                <img className="companyLogo" src={userAPI.getResourceURL(customer.logoURL)} alt="Logo" />
                                                :
                                                (settings &&
                                                    <img className="companyLogo" src={settings && settings.corporateIdentity && settings.corporateIdentity.logoImage ? settings.corporateIdentity.logoImage : 'https://react-pdf.org/images/logo.png'} alt="Logo" />
                                                )
                                            )
                                            :
                                            (
                                                <img className="companyLogo" src={imageList[0]['data_url']} alt="" />
                                            )
                                        }
                                    </div>
                                    <div className="marginTopL displayFlex gapM">
                                        <PrimaryButton onClick={onImageUpload}>
                                            {t('settingsPanel.companyPanel.uploadFromFile')}
                                        </PrimaryButton>
                                        <PrimaryButton onClick={onImageRemoveAll}>{t('global.clear')}</PrimaryButton>
                                    </div>
                                </div>
                            )}
                        </ImageUploading>
                    </div>
                </Section>
                <Section title={'Theming'}>
                    <div className="width500">
                        <div className='displayFlex justifyContentEnd'>
                            <DefaultButton text={t('global.change')} onClick={() => setEditTheme({ ...theme })} />
                        </div>
                        <div className="marginTopL displayFlex gapM flexWrap">
                            {styleCalculator.map((parameters: any, index: number) =>
                                <div className='displayFlex alignItemsCenter flexDirectionColumn gapS'>
                                    <div className='width80 height80' style={{ backgroundColor: theme[parameters.field] }}></div>
                                    <div className='textXS'>{parameters.name}</div>
                                </div>)}
                        </div>
                    </div>
                </Section>
            </div>
            <div className='displayFlex'>
                <Section title={t('settingsPanel.companyPanel.address')}>
                    <div className='displayFlex justifyContentEnd'>
                        <DefaultButton text={t('global.change')} onClick={toggleAddressDialogOpen} />
                    </div>
                    <div className="marginTopM">
                        <div>
                            <Text variant="medium">{customer.name}</Text>
                        </div>
                        <div>
                            <Text variant="medium">{customer.address.street} {customer.address.streetNumber}</Text>
                        </div>
                        <div>
                            <Text variant="medium">{customer.address.postalCode} {customer.address.city}</Text>
                        </div>
                    </div>
                </Section>
            </div>
        </div>
        <Dialog hidden={!addressDialogOpen}
            onDismiss={toggleAddressDialogOpen}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('settingsPanel.companyPanel.changeAddress'),
            }}
            modalProps={{
                isBlocking: true,
            }}>
            <TextField label={t('settingsPanel.companyPanel.street')} value={editedAddress.street} onChange={(e: any, value: any) => setEditedAddress({ ...editedAddress, street: value })}
                errorMessage={isStreetValid() ? undefined : t('settingsPanel.companyPanel.streetNotValid')} />
            <TextField label={t('settingsPanel.companyPanel.streetNumber')} value={editedAddress.streetNumber} onChange={(e: any, value: any) => setEditedAddress({ ...editedAddress, streetNumber: value })}
                errorMessage={isStreetNumberValid() ? undefined : t('settingsPanel.companyPanel.streetNumberNotValid')} />
            <TextField label={t('settingsPanel.companyPanel.postalCode')} value={editedAddress.postalCode} onChange={(e: any, value: any) => setEditedAddress({ ...editedAddress, postalCode: value })}
                errorMessage={isPostalCodeValid() ? undefined : t('settingsPanel.companyPanel.postalCodeNotValid')} />
            <TextField label={t('settingsPanel.companyPanel.city')} value={editedAddress.city} onChange={(e: any, value: any) => setEditedAddress({ ...editedAddress, city: value })}
                errorMessage={isCityValid() ? undefined : t('settingsPanel.companyPanel.cityNotValid')} />
            <DialogFooter className="marginTopXXL">
                <PrimaryButton disabled={!isAddressValid()} text={t('global.change')} onClick={async () => {
                    try {
                        const updatedCustomer = { ...customer, address: editedAddress }

                        await updateCustomer(updatedCustomer);

                        setCustomer(updatedCustomer);
                        toggleAddressDialogOpen();
                    } catch (error: any) {
                        properties.processError(error);
                    }
                }} />
                <DefaultButton onClick={toggleAddressDialogOpen} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
        <Dialog hidden={editTheme === null}
            onDismiss={() => setEditTheme(null)}
            maxWidth={1100}
            minWidth={1100}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Change Theme',
            }}
            modalProps={{
                isBlocking: true,
            }}>
            <div className='paddingM displayFlex gapM'>
                <div className='width600'>
                    <ThemeProvider theme={{
                        palette: {
                            themePrimary: editTheme ? editTheme.primary : '#FFFFFF',
                        },
                    }}>
                        <div>
                            <div className='textL'>Samples</div>
                            <div className='marginTopL displayFlex gapM'>
                                <div>
                                    <SideBar items={testSideBarItems} setItem={() => { }}></SideBar>
                                </div>
                                <div>
                                    <div>
                                        <DefaultButton>Default Button</DefaultButton>
                                    </div>
                                    <div className='marginTopM'>
                                        <PrimaryButton>Primary Button</PrimaryButton>
                                    </div>
                                    <div className='marginTopL'>
                                        <Pivot linkSize="large">
                                            <PivotItem headerText="Tab 1">
                                            </PivotItem>
                                            <PivotItem headerText="Tab 2">
                                            </PivotItem>
                                            <PivotItem headerText="Tab 3">
                                            </PivotItem>
                                        </Pivot>
                                    </div>
                                    <div className='marginTopL'>
                                        <Toggle label="Toggle" onText="On" offText="Off" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ThemeProvider>
                </div>
                <div className="width500">
                    <div className='textL'>Theme Colors</div>
                    <div className="marginTopL displayFlex gapM flexWrap">
                        {styleCalculator.map((parameters: any) =>
                            <div className='displayFlex alignItemsCenter flexDirectionColumn gapS'>
                                <div className='width80 height80' style={{ backgroundColor: editTheme ? editTheme[parameters.field] : '#FFFFFF' }}
                                    onClick={() => {
                                        setEditColor(editTheme[parameters.field]);
                                        setEditField(parameters.field);
                                    }}></div>
                                <div className='textXS'>{parameters.name}</div>
                            </div>)}
                    </div>
                </div>
            </div>
            <DialogFooter className="marginTopL">
                <PrimaryButton text={t('global.save')} onClick={async () => {

                    try {
                        await createOrUpdateCustomerTheme(new CustomerTheme({ ...editTheme }));

                        setEditTheme(null);

                        // Reloaf page for the changed theme to become effective
                        window.location.reload();
                    } catch (error) {
                        properties.processError(error);
                    }
                }} />
                <DefaultButton onClick={() => {
                    setEditTheme(null);
                }} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
        <Dialog hidden={editField === null}
            onDismiss={() => setEditField(null)}
            maxWidth={450}
            minWidth={450}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Change Color',
            }}
            modalProps={{
                isBlocking: true,
            }}>
            <div className='displayFlex flexDirectionColumn alignItemsCenter'>
                <ColorPicker
                    color={editColor}
                    onChange={(x: any, color: any) => { console.log(color); setEditColor(color.hex) }}
                    alphaType={'none'}
                    showPreview={true}
                    styles={{
                        panel: { padding: 12 },
                        root: {
                            maxWidth: 352,
                            minWidth: 352,
                        },
                        colorRectangle: { height: 268 },
                    }}
                    // The ColorPicker provides default English strings for visible text.
                    // If your app is localized, you MUST provide the `strings` prop with localized strings.
                    strings={{
                        // By default, the sliders will use the text field labels as their aria labels.
                        // Previously this example had more detailed instructions in the labels, but this is
                        // a bad practice and not recommended. Labels should be concise, and match visible text when possible.
                        hueAriaLabel: 'Hue',
                    }}
                />
            </div>
            <DialogFooter className="marginTopL">
                <PrimaryButton text={t('global.save')} onClick={async () => {
                    const newTheme = { ...editTheme };

                    newTheme[editField] = `#${editColor}`;

                    setEditTheme({ ...newTheme });
                    setEditField(null);
                }} />
                <DefaultButton onClick={() => {
                    setEditField(null);
                }} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
    </div>;
}