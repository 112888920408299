import { ActionButton, CommandBar, DefaultButton, Dropdown, ICommandBarItemProps, IDropdownOption, PrimaryButton, Text } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';
import { userAPI } from "../UserAPI";
import InfoList from "./InfoList";
import DIN276AssetProperties from "./DIN276AssetProperties";
import Dialog, { DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { EntityQuery, LinkedEntityQuery, Product, Query, RelationshipDirection, Document } from "@marc.gille-sepehri/tri-model";
import DataTable from "react-data-table-component";
import { configuration } from '../Configuration';
import { dataTableStyles } from "../styles";

class Properties {
  asset: any;
}

export default function AssetProperties(properties: Properties) {
  const { t } = useTranslation();
  const [tab, setTab] = useState('general');
  const [chartOptions, setChartOptions] = useState() as any;
  const [series, setSeries] = useState() as any;
  const [metric, setMetric] = useState('pressure');
  const [assetProperties, setAssetProperties] = useState(properties.asset.properties || {});
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [asset, setAsset] = useState(properties.asset) as any;
  const [editedAsset, setEditedAsset] = useState({}) as any;
  const [products, setProducts] = useState([]) as any;

  console.log(asset);

  const toggelEditDialogOpen = () => {
    setEditDialogOpen(!editDialogOpen);
    setEditedAsset({ ...asset })
  }
  const _items: ICommandBarItemProps[] = [
    {
      key: 'general',
      text: t('assetPropertiesPanel.general'),
      iconProps: { iconName: 'Info' },
      onClick: () => setTab('general'),
    },
    {
      key: 'measurements',
      text: t('assetPropertiesPanel.measurements'),
      iconProps: { iconName: 'LineChart' },
      onClick: () => setTab('measurements'),
    },
    {
      key: 'product',
      text: 'Produkt',
      iconProps: { iconName: 'Document' },
      onClick: () => setTab('product'),
    }
  ];
  const productColumns = [{
    name: t('buildingProperties.technicalEquipmentPropertiesPanel.document'),
    selector: (product: any) => product._id,
    cell: (product: any) => (
      <div className='height20 displayFlex flexDirectionColumn justifyContentCenter'>
        <a href={`${configuration.serverUrl}${product.manual.url}`} target="_blank">
          <ActionButton iconProps={{ iconName: 'PDF' }}></ActionButton>
        </a>
      </div>
    ),
  }, {
    name: t('buildingProperties.technicalEquipmentPropertiesPanel.product'),
    selector: (product: any) => product.name,
    cell: (product: any) => (
      <div className='height20 displayFlex flexDirectionColumn justifyContentCenter overflowWrapWordWrap'>
        <Text variant='medium'>{product.name}</Text>
      </div>
    ),
    sortable: true,
  }];

  const metricOptions: IDropdownOption[] = [
    { key: 'pressure', text: 'Druck (bar)' },
    { key: 'occupancy', text: 'Belegung (Personen)' },
    { key: 'energyConsumption', text: 'Energieverbrauch (kWh)' },
  ];

  useEffect(() => {
    const call = async () => {
      const metricValueSeries = await userAPI.queryTelemetry({ metric, asset: asset.id });

      console.log(metricValueSeries);

      setSeries([{
        name: t('Druck'),
        data: metricValueSeries.map((measurement: any) => measurement.value),
      }]);
      setChartOptions({
        chart: {
          height: 350,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#00288E', '#D36200'],
        stroke: {
          curve: 'smooth',
          width: [1, 1],
        },
        xaxis: {
          type: 'datetime',
          categories: metricValueSeries.map((measurement: any) => measurement.timestamp)
        },
        yaxis: [{
          seriesName: t('Druck'),
          min: 0,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#00288E'
          },
          labels: {
            style: {
              colors: '#00288E',
            },
            formatter: (val: number) => {
              return val.toFixed(1)
            },
          },
          title: {
            text: t('Druck') + ' (bar)',
            style: {
              color: '#00288E',
            }
          }
        },
        ],
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
      })
    };

    call();
  }, [metric]);

  useEffect(() => {
    const call = async () => {
      try {
        const result = await userAPI.query(new Query(new EntityQuery(Product.type),
          [new LinkedEntityQuery('manual', RelationshipDirection.out, Document.type)]));

        console.log(result);

        setProducts(result);
      } catch (error) {
      }
    };

    call();
  }, [properties.asset]);

  return (
    <>
      <div>
        <div>
          <Text variant="medium">{t('assetPropertiesPanel.asset')}</Text>
        </div>
        <div>
          <div>
            <Text variant="xxLarge">{asset.id}/{asset.name}</Text>
          </div>
          <div>
            <Text variant="medium">{asset ? `${asset.postalCode} ${asset.city}` : ''}</Text>
          </div>
        </div>
        <div className="marginTopL">
          <CommandBar
            styles={{ root: { padding: '0px', } }}
            items={_items}
          />
        </div>
        {(() => {
          switch (tab) {
            case 'general': return <div className="marginTopL">
              <div className="displayFlex justifyContentEnd">
                <PrimaryButton text={t('global.edit')} onClick={() => {
                  toggelEditDialogOpen();
                }} />
              </div>
              <InfoList list={[
                {
                  label: t('assetPropertiesPanel.schema'),
                  value: asset.schema,
                },
                {
                  label: t('assetPropertiesPanel.schemaCategory'),
                  value: asset.schemaCategory,
                },
              ]}></InfoList>
              {asset.schema === 'din276'
                ?
                <div className="marginTopL">
                  <Text variant="large">DIN 276 Eigenschaften</Text>
                  <div className="marginTopL">
                    <DIN276AssetProperties readonly type={asset.schemaCategory} asset={asset.properties} setAsset={() => { }}></DIN276AssetProperties>
                  </div>
                </div>
                :
                <></>
              }
            </div>;
            case 'measurements': return <div className="marginTopL">
              <div>
                <Dropdown
                  label="Messgröße"
                  options={metricOptions}
                  selectedKey={metric}
                  onChange={(e: any, item: any) => setMetric(item.key)}
                  styles={{
                    dropdown: { width: 300 },
                  }}
                />
              </div>
              {
                chartOptions && series && series.length && series[0].data.length > 0
                  ?
                  <>
                    <Chart options={chartOptions} series={series} type="area" />
                  </>
                  :
                  <div className='marginTopM'>{t('global.noInformationAvailable')}</div>
              }
            </div>;
            case 'product': return <div>
              <div className="marginTopM">
                <Text variant='large'>{t('buildingProperties.technicalEquipmentPropertiesPanel.documentation')}</Text>
              </div>
              <DataTable
                data={products}
                columns={productColumns}
                customStyles={dataTableStyles}
                pagination
              />
            </div>
          }
        })()}
      </div>
      <Dialog hidden={!editDialogOpen}
        onDismiss={toggelEditDialogOpen}
        maxWidth={700}
        minWidth={700}
        dialogContentProps={{
          type: DialogType.normal,
          title: properties.asset.name,
          //subText: 'Do you want to send this message without a subject?',
        }}
        modalProps={{
          isBlocking: true,
        }}
      >
        <DIN276AssetProperties type={editedAsset.schemaCategory} asset={editedAsset.properties} setAsset={(properties: any) => setEditedAsset({ ...editedAsset, properties })}></DIN276AssetProperties>
        <DialogFooter className="marginTopXXL">
          <PrimaryButton text={t('global.apply')} onClick={() => {
            setAsset(editedAsset);
            toggelEditDialogOpen();
          }} />
          <DefaultButton onClick={toggelEditDialogOpen} text={t('global.cancel')} />
        </DialogFooter>
      </Dialog>
    </>
  );
}
