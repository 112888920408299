export const assetIcons = [
    {
        id: 'fire-extinguisher',
        label: 'Fire Extinguisher',
        color: '#FF0000',
    }, {
        id: 'fire-hydrant',
        label: 'Fire Hydrant',
        color: '#FF0000',
    }, {
        id: 'pacemaker',
        label: 'Pacemaker',
        color: '#FF0000',
    }, {
        id: 'defibrillator',
        label: 'Defibrillator',
        color: '#FF0000',
    }, {
        id: 'heat-pump',
        label: 'Heat Pump',
        color: '#0000FF',
    }, {
        id: 'fan',
        label: 'Fan',
        color: 'grey',
    }, {
        id: 'valve',
        label: 'Valve',
        color: 'grey',
    }, {
        id: 'electricity-meter',
        label: 'Electricity Meter',
        color: 'grey',
    }, {
        id: 'escalator',
        label: 'Escalator',
        color: 'grey',
    }
];

export const getAssetIcon = (id: string) => assetIcons.find((icon: any) => icon.id === id);

interface IconProperties {
    id: string;
}

export function Icon(properties: IconProperties) {
    return <img src={`svgs/${properties.id}.svg`} alt={properties.id}></img>
}