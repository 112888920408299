import '../App.sass';
import { useEffect, useState } from 'react';
import { ActionButton, ComboBox, DefaultButton, IComboBox, IComboBoxOption, Label, PrimaryButton, SelectableOptionMenuItemType, TextField } from '@fluentui/react';
import Section from '../Section';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import { Action, AndCondition, BusinessRule, BusinessRuleRuntime, BusinessRuleRuntimeStatus, ComparisonCondition, Condition, EmailNotification, EntityQuery, OrCondition, Product, Query, SMSNotification } from '@marc.gille-sepehri/tri-model';
import { dataTableStyles } from '../styles';
import { userAPI } from '../UserAPI';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { useAdministrationAPI } from '../AdministrationAPI';
import ConditionSection from '../components/ConditionSection';

interface Properties {
    processError: (error: any) => void;
}

const products = [
    { key: 'ChargingStation', text: 'Ladestationen' },
    { key: 'BusStop', text: 'Bushaltestelle' },
];

const customerOptions = [
    { key: '_all', text: 'Alle', itemType: SelectableOptionMenuItemType.SelectAll },
    { key: '4711', text: 'PENNY Markt GmbH' },
    { key: '4712', text: 'Media Markt GmbH' },
    { key: '4713', text: 'Bergmannsheil Krankenhaus GmbH' },
];

const selectableCustomerOptions = customerOptions.filter((option: any) =>
    (option.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) && !option.disabled,
);

const buildings = [
    { key: '_all', text: 'Alle' },
];


interface AlertPanelProperties {
    alert: BusinessRule;
    setAlert: (alert: BusinessRule | null) => void;
    processError: (error: any) => void;
}

function AlertPanel(properties: AlertPanelProperties) {
    const { createOrUpdateBusinessRule } = useAdministrationAPI();
    const [conditions, setConditions] = useState(properties.alert.conditions || []) as any;
    const [actions, setActions] = useState(properties.alert.actions || []) as any;
    const [selectedCustomers, setSelectedCustomers] = useState([]) as any;
    const [products, setProducts] = useState([]) as any;
    const [name, setName] = useState(properties.alert.name || '') as any;
    const [description, setDescription] = useState(properties.alert.description || '') as any;

    useEffect(() => {
        const call = async () => {
            try {
                setProducts(await userAPI.query(new Query(new EntityQuery(Product.type))));
            } catch (error) {
                properties.processError(error);
            }
        };

        call();
    }, []);

    const onCustomersChange = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
        index?: number,
        value?: string,
    ): void => {
        const selected = option?.selected;
        const currentSelectedOptionKeys = selectedCustomers.filter((key: any) => key !== '_all');
        const selectAllState = currentSelectedOptionKeys.length === selectableCustomerOptions.length;

        if (option) {
            if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
                selectAllState
                    ? setSelectedCustomers([])
                    : setSelectedCustomers(['selectAll', ...selectableCustomerOptions.map(o => o.key as string)]);
            } else {
                const updatedKeys = selected
                    ? [...currentSelectedOptionKeys, option!.key as string]
                    : currentSelectedOptionKeys.filter((k: any) => k !== option.key);

                if (updatedKeys.length === selectableCustomerOptions.length) {
                    updatedKeys.push('_all');
                }

                setSelectedCustomers(updatedKeys);
            }
        }
    };

    const addCondition = (condition: Condition) => {
        setConditions([...conditions, condition]);
    };

    const removeCondition = (condition: Condition) => {
        setConditions(conditions.filter((entry: Condition) => condition !== entry));
    };

    const removeAction = (action: Action) => {
        setActions(actions.filter((entry: Action) => action !== entry));
    };

    const conditionMenuProps = {
        items: [
            {
                key: 'comparison',
                text: 'Comparison',
                onClick: () => {
                    console.log(conditions);
                    addCondition(new ComparisonCondition())
                },
            },
            {
                key: 'and',
                text: 'AND Operation',
                onClick: () => addCondition(new AndCondition()),
            },
            {
                key: 'or',
                text: 'OR Operation',
                onClick: () => addCondition(new OrCondition()),
            },
        ],
    };

    const reactionMenuProps = {
        shouldFocusOnMount: true,
        items: [
            {
                key: 'email',
                text: 'E-Mail',
                onClick: () => setActions([...actions, new EmailNotification({})]),
            },
            {
                key: 'sms',
                text: 'SMS',
                onClick: () => setActions([...actions, new SMSNotification({})]),
            },
        ],
    };

    return <div className='marginTopM displayFlex flexDirectionColumn gapM'>
        <div className="displayFlex gapM">
            <div className="flexGrow1 displayFlex textL">
                {name}
            </div>
            <div className='displayFlex gapM'>
                <DefaultButton text="Cancel" onClick={() => {
                    properties.setAlert(null);
                }} />
                <PrimaryButton text={properties.alert._id ? 'Save' : 'Add'} onClick={async () => {
                    try {
                        await createOrUpdateBusinessRule(new BusinessRule({
                            ...properties.alert,
                            name,
                            description,
                            conditions,
                            actions,
                        }));

                        properties.setAlert(null);
                    }
                    catch (error) {
                        properties.processError(error);
                    }
                }} /></div>
        </div>
        <div className="paddingM displayFlex gapL">
            <div className="displayFlex gapS">
                <Label>Name</Label>
                <TextField value={name} onChange={(e: any, name: string | undefined) => setName(name)} styles={{ fieldGroup: { width: 300 } }} />
            </div>
            <div className="displayFlex gapS">
                <Label>Description</Label>
                <TextField value={description} onChange={(e: any, description: string | undefined) => setDescription(description)} styles={{ fieldGroup: { width: 700 } }} />
            </div>
        </div>
        <Section title="Focus">
            <div className='displayFlex gapL'>
                <ComboBox
                    label="Produkte"
                    //selectedKeys={products}
                    // @ts-ignore
                    //onChange={onPOITypesSelectionChange}
                    multiSelect
                    options={products.map((product: Product) => {
                        return {
                            key: product._id,
                            text: product.name,
                        }
                    })}
                    styles={{ root: { width: 300 } }}
                />
                <ComboBox
                    label="Customers"
                    //selectedKeys={products}
                    // @ts-ignore
                    //onChange={onPOITypesSelectionChange}
                    multiSelect
                    options={customerOptions}
                    onChange={onCustomersChange}
                    styles={{ root: { width: 300 } }}
                />
                <ComboBox
                    label="Projects/Buildings"
                    //selectedKeys={products}
                    // @ts-ignore
                    //onChange={onPOITypesSelectionChange}
                    multiSelect
                    options={buildings}
                    styles={{ root: { width: 300 } }}
                />
            </div>
        </Section>
        <Section title="Conditions">
            <ActionButton menuProps={conditionMenuProps}>
                Bedingung ergänzen
            </ActionButton>
            {conditions.map((condition: Condition, index: number) => <div key={`condition${index}`} className='marginBottomS displayFlex alignItemsCenter gapM'>
                <ActionButton iconProps={{ iconName: 'Delete' }}
                    onClick={() => {
                        removeCondition(condition);
                    }}>
                </ActionButton>
                <ConditionSection condition={condition} setCondition={(condition: Condition) => {
                    const newConditions = [...conditions];

                    newConditions[index] = condition;

                    setConditions(newConditions);
                }}></ConditionSection>
            </div>)}
        </Section>
        <Section title="Actions">
            <ActionButton menuProps={reactionMenuProps}>
                Aktion ergänzen
            </ActionButton>
            {actions.map((action: Action, index: number) => <div key={`condition${index}`} className='marginBottomS displayFlex alignItemsCenter gapM'>
                <ActionButton iconProps={{ iconName: 'Delete' }}
                    onClick={() => {
                        removeAction(action);
                    }}>
                </ActionButton>
                {(() => {
                    if (action instanceof EmailNotification) {
                        return <div className='borderLeftNeutral displayFlex gapM'>
                            <div className='displayFlex flexDirectionColumn  alignItemsCenter paddingS textOrientationUp'>
                                <span className='textOrientationUp fontWeightBold primaryColor'>E-Mail</span>
                            </div>
                            <div>
                                <TextField label="Addressees" value={(action as EmailNotification).to} styles={{ fieldGroup: { width: 300 } }}
                                    onChange={(event: any, value: string | undefined) => {
                                        const newAction = Action.unmarshal({ ...action, to: value });
                                        const newActions = [...actions];

                                        newActions[index] = newAction;

                                        setActions(newActions);
                                    }} />
                                <TextField label="Subject" value={(action as EmailNotification).subject} styles={{ fieldGroup: { width: 300 } }}
                                    onChange={(event: any, value: string | undefined) => {
                                        const newAction = Action.unmarshal({ ...action, subject: value });
                                        const newActions = [...actions];

                                        newActions[index] = newAction;

                                        setActions(newActions);
                                    }} />
                            </div>
                            <TextField label="Content" value={(action as EmailNotification).content} multiline styles={{ fieldGroup: { width: 400, height: 100 } }}
                                onChange={(event: any, value: string | undefined) => {
                                    const newAction = Action.unmarshal({ ...action, content: value });
                                    const newActions = [...actions];

                                    newActions[index] = newAction;

                                    setActions(newActions);
                                }} />
                        </div>
                    } else if (action instanceof SMSNotification) {
                        return <div className='borderLeftNeutral displayFlex gapM'>
                            <div className='displayFlex flexDirectionColumn  alignItemsCenter paddingS textOrientationUp'>
                                <span className='textOrientationUp fontWeightBold primaryColor'>SMS</span>
                            </div>
                            <div>
                                <TextField label="Phone number" value={(action as SMSNotification).phoneNumber} styles={{ fieldGroup: { width: 300 } }} />
                            </div>
                            <TextField label="Message" value={(action as SMSNotification).content} multiline styles={{ fieldGroup: { width: 400, height: 100 } }} />
                        </div>
                    } else {
                        return <></>;
                    }
                })()}
            </div>)}
        </Section>
    </div>;
}

export default function AlertManagementPanel(properties: Properties) {
    const { t } = useTranslation();
    const { activateBusinessRule, deactivateBusinessRule, deleteBusinessRule } = useAdministrationAPI();
    const [alerts, setAlerts] = useState([]) as any;
    const [selectedAlert, setSelectedAlert] = useState() as any;
    const [nextRefresh, setNextRefresh] = useState(0);
    const [refreshing, setRefreshing] = useState(false);

    const loadRuntimes = async () => {
        setRefreshing(true);

        try {
            const alerts = await userAPI.query(new Query(new EntityQuery(BusinessRule.type)));

            console.log('Alerts >>>', alerts);

            const runtimes = await userAPI.query(new Query(new EntityQuery(BusinessRuleRuntime.type)));

            console.log('Runtimes >>>', runtimes);

            alerts.forEach((alert) => {
                alert._runtime = runtimes.find((runtime: BusinessRuleRuntime) => runtime.businessRule === alert._id);
            });

            console.log('Alerts >>>', alerts);

            setAlerts(alerts);
        } catch (error) {
            properties.processError(error);
        } finally {
            setRefreshing(false);
            setNextRefresh(60);
        }
    };

    const columns = [{
        name: '',
        width: '20px',
        cell: (alert: any) => <ActionButton iconProps={{ iconName: 'Delete' }}
            onClick={async () => {
                await deleteBusinessRule(alert);
                setAlerts(await userAPI.query(new Query(new EntityQuery(BusinessRule.type))));
            }}>
        </ActionButton>,
        sortable: true,
    }, {
        name: '',
        width: '120px',
        cell: (alert: any) => <div className='flexGrow1 displayFlex justifyContentCenter'>
            {alert._runtime
                ?
                (alert._runtime.status === BusinessRuleRuntimeStatus.stopped
                    ?
                    <ActionButton onClick={async () => {
                        try {
                            await activateBusinessRule(alert);
                            await loadRuntimes();
                        } catch (error) {
                            properties.processError(error);
                        }
                    }}>
                        Activate
                    </ActionButton>
                    :
                    <ActionButton onClick={async () => {
                        try {
                            await deactivateBusinessRule(alert);
                            await loadRuntimes();
                        } catch (error) {
                            properties.processError(error);
                        }
                    }}>
                        Deactivate
                    </ActionButton>
                )
                :
                <ActionButton onClick={async () => {
                    try {
                        await activateBusinessRule(alert);
                        await loadRuntimes();
                    } catch (error) {
                        properties.processError(error);
                    }
                }}>
                    Activate
                </ActionButton>
            }
        </div>,
        sortable: true,
    }, {
        name: 'Status',
        width: '100px',
        cell: (alert: any) => <div className='flexGrow1 displayFlex'>
            {alert._runtime
                ?
                <Spinner size={SpinnerSize.small} />
                :
                <span>?</span>
            }
        </div>,
        sortable: true,
    }, {
        name: 'Activation',
        width: '200px',
        cell: (alert: any) => <div className='flexGrow1 displayFlex'>
            <span>{alert._runtime && alert._runtime.activation ? `${new Date(alert._runtime.activation).toLocaleDateString()} ${new Date(alert._runtime.activation).toLocaleTimeString()}` : '-'}</span>
        </div>,
        sortable: true,
    }, {
        name: 'Last Check',
        width: '200px',
        cell: (alert: any) => <div className='flexGrow1 displayFlex'>
            <span>{alert._runtime && alert._runtime.lastCheck ? `${new Date(alert._runtime.lastCheck).toLocaleDateString()} ${new Date(alert._runtime.lastCheck).toLocaleTimeString()}` : '-'}</span>
        </div>,
        sortable: true,
    }, {
        name: 'Last Execution',
        width: '200px',
        cell: (alert: any) => <div className='flexGrow1 displayFlex'>
            <span>{alert._runtime && alert._runtime.lastExecution ? `${new Date(alert._runtime.lastExecution).toLocaleDateString()} ${new Date(alert._runtime.lastExecution).toLocaleTimeString()}` : '-'}</span>
        </div>,
        sortable: true,
    }, {
        name: 'Name',
        selector: (alert: any) => alert.name,
        width: '200px',
        cell: (alert: any) => <span>{alert.name}</span>,
        sortable: true,
    }, {
        name: 'Description',
        selector: (alert: any) => alert.description,
        width: '300px',
        cell: (alert: any) => <span>{alert.description}</span>,
        sortable: true,
    }];

    useEffect(() => {
        const interval = setInterval(() => {
            const newNextRefresh = nextRefresh - 5;

            if (newNextRefresh <= 0) {
                loadRuntimes();
            } else {
                setNextRefresh(newNextRefresh);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [nextRefresh]);

    return <><div>
        {selectedAlert
            ?
            <AlertPanel alert={selectedAlert} setAlert={async (alert: BusinessRule | null) => {
                setSelectedAlert(null);
                setAlerts(await userAPI.query(new Query(new EntityQuery(BusinessRule.type))));
            }} processError={properties.processError}></AlertPanel>
            :
            <div>
                <div className='displayFlex'>
                    <ActionButton iconProps={{ iconName: 'CommentAdd' }}
                        onClick={() => {
                            const alert = new BusinessRule({});

                            setSelectedAlert(alert);
                        }}>
                        Add alert
                    </ActionButton>
                    <div className='flexGrow1 displayFlex alignItemsCenter justifyContentEnd gapXS'>
                        <div className='textXS'>{refreshing ? 'Refreshing ...' : `Next refresh in ${nextRefresh} seconds.`}</div>
                        <ActionButton disabled={refreshing} iconProps={{ iconName: 'Refresh' }}
                            onClick={() => {
                                loadRuntimes();
                            }}>
                        </ActionButton>
                    </div>
                </div>
                <div className='marginTopL borderNeutral'>
                    <DataTable
                        data={alerts}
                        columns={columns}
                        customStyles={dataTableStyles}
                        selectableRows
                        selectableRowsSingle
                        onSelectedRowsChange={({ selectedRows }) => {
                            setSelectedAlert(selectedRows && selectedRows.length > 0 ? selectedRows[0] : null);
                        }}
                        pagination
                        paginationPerPage={5}
                    />
                </div>
            </div>
        }
    </div>
    </>;
}