import { makeStyles, shorthands } from "@fluentui/react-components";
import { PDFViewer, PDFDownloadLink, Document, Page, Image, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { settingsAtom } from '../State';
import { userAPI } from '../UserAPI';
import { useAtom } from "jotai";
import { authenticationAtom } from '../State';
import { ActionButton, IconButton } from '@fluentui/react/lib/Button';
import { Fragment } from "react/jsx-runtime";
import ApexCharts from 'apexcharts'
import { v4 as uuidv4 } from 'uuid';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { useEffect, useState } from 'react';
import { mergeStyles } from '@fluentui/react';

// General reporting settings

Font.register({ family: 'Lato', src: 'http://fonts.gstatic.com/s/lato/v13/v0SdcGFAl2aezM9Vq_aFTQ.ttf' });

const fontFamily = 'Lato';
const useStyles = makeStyles({
    list: {
        ...shorthands.border('1px', 'solid', '#EFEFEF'),
        ...shorthands.padding('10px'),
        backgroundColor: 'white',
    },
    titleBar: {
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        fontWeight: 'bold',
        marginRight: '10px',
    },
    downloadLink: {
        marginTop: '10px',
        display: 'flex',
        gap: '10px',
        textDecoration: 'none',
    },
});

// <<<<<<<<<<<<<<<<<<<

const styles = StyleSheet.create({
    page: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
        fontFamily: fontFamily,
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
        fontFamily: fontFamily,
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: fontFamily,
    },
    logo: {
        marginVertical: 10,
        marginHorizontal: 10,
        width: 70,
    },
    pageHeader: {
        fontSize: 10,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },

    // Text body

    paragraph: {
        fontSize: 11,
        fontFamily: fontFamily,
        marginBottom: 16,
    },
    header1: {
        fontSize: 14,
        fontWeight: 'bold',
        marginTop: 20,
        marginBottom: 20,
        fontFamily: fontFamily,
    },

    // Layout

    centered: {
        flexDirection: "row",
        flexGrow: 1,
        justifyContent: 'center',
    },

    // Table styles

    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginBottom: 16,
    },
    row: {
        fontSize: 12,
        flexDirection: "row",
        alignItems: "center",
    },
    cell: {
        width: "20%",
        fontSize: 9,
    }
});

const PDFChart = ({ options }) => {
    const id = uuidv4();

    options.chart.id = id;
    options.chart.width = 1000;
    options.chart.height = 500;
    options.chart.animations = { enabled: false };

    try {
        const container = document.createElement("div");

        container.id = id;
        container.style.visibility = "hidden";

        document.getElementById("sandbox").appendChild(container);

        const chart = new ApexCharts(container, options);

        console.log('Chart >>>', chart);

        const provider = new Promise(async (resolve, reject) => {
            await chart.render();

            const base64 = await chart.dataURI()

            resolve(base64.imgURI);
            document.getElementById("sandbox").removeChild(container);
        });

        return <Image src={provider} style={{ width: 300, height: 150 }}></Image>;
    } catch (error) {
        console.log(error);

        return <Text>Error in Image</Text>;
    }
}

const PDFTable = ({ data, options }) => {
    return <View style={styles.tableContainer}>
        {/* <View>
            <>
                {options.headers.map((header) =>
                    <Text>{header}</Text>)
                }
            </>
        </View> */}
        <Fragment>
            {data.map((row) =>
                <View style={styles.row}>
                    {row.map((value) => <Text style={{ ...styles.cell, width: `"${100 / row.length}%"` }}>{value}</Text>)}
                </View>
            )}
        </Fragment>
    </View>
}

// >>>>>>>>>>>>>>>>

// TODO
// For now, report templates for specific skills are hardcoded here
// Will move to Node (and skill definitions there as soon as we have sorted out Apex rendering)

const FireExtinguisherReport = ({ authentication, resource, settings }) => {
    // Some data aggregations

    const totalFireExtinguisherCount = 66;
    const totalSubsidiaryCount = 22;
    const totalCompanyCount = 2;
    const costSavingsPotential = 43;
    const co2SavingsPotential = 12;

    const headers = ['Name', 'HR', 'Rechtsform', 'Zweck', 'Adresse', 'Etagen', 'Gesch. Fläche', 'Bedarf Feuerlöscher'];
    const chartOptions = {
        series: [{
            name: "Desktops",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
        }],
        chart: {
            type: 'line',
            zoom: {
                enabled: false
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: 'Product Trends by Month',
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        }
    };

    const companyPieChartOptions = {
        series: [425, 844, 312],
        chart: {
            width: 800,
            height: 800,
            type: 'pie',
        },
        labels: ['Siemens', 'Deutsche Bank', 'Mainova'],
    };

    const postalCodePieChartOptions = {
        series: [44, 55, 13],
        chart: {
            width: 800,
            height: 800,
            type: 'pie',
        },
        labels: ['60594', '60382', '60322'],
    };

    return <Document>
        <Page size="A4" style={styles.page}>
            <Text style={styles.pageHeader} fixed>
                ~ Created with The Real Insight ~
            </Text>
            <Image style={styles.logo} src={settings && settings.corporateIdentity && settings.corporateIdentity.logoImage ? userAPI.getResourceURL(settings.corporateIdentity.logoImage) : 'https://react-pdf.org/images/logo.png'}></Image>
            <View style={styles.section}>
                <Text>Bedarfe für Feuerlöscher in Frankfurt</Text>
            </View>
            <Text style={styles.header1}>Zusammenfassung</Text>
            <Text style={styles.paragraph}>
                In Frankfurt besteht in in ingesamt {totalSubsidiaryCount} Niederlassungen von ingesamt {totalCompanyCount} Unternehmen ein Bedarf an ingesamt {totalFireExtinguisherCount} Feuerlöschern.
                Bei einem Einsparpotential von {costSavingsPotential}€ und {co2SavingsPotential}kg CO2 pro Feuerlöscher entspricht das einem Gesamteinsparpotential von {costSavingsPotential * totalFireExtinguisherCount}€ und {co2SavingsPotential * totalFireExtinguisherCount} kg CO2.
            </Text>
            <Text style={styles.paragraph}>
                Die Niederlassungen befinden sich auf einer Fläche von insgesamt 77 qkm.
            </Text>
            <Text style={styles.header1}>Bedarfe nach Anzahl Feuerlöscher</Text>
            <PDFTable data={resource.data.buildings} options={{ headers }}></PDFTable>
            <Text style={styles.header1}>Bedarfe nach Unternehmen</Text>
            <Text style={styles.paragraph}>Geordnet nach Unternehmen bestehen die Bedarfe wie folgt:</Text>
            <PDFTable data={resource.data.buildings} options={{ headers }}></PDFTable>
            <Text style={styles.paragraph}>Und in der grafischen Übersicht</Text>
            <View style={styles.centered}>
                <PDFChart options={companyPieChartOptions}></PDFChart>
            </View>
            <Text style={styles.header1}>Bedarfe nach Postleitzahlgebieten</Text>
            <Text style={styles.paragraph}>Geordnet nach Postleizahlen bestehen die Bedarfe wie folgt:</Text>
            <PDFTable data={resource.data.buildings} options={{ headers }}></PDFTable>
            <Text style={styles.paragraph}>Und in der grafischen Übersicht</Text>
            <View style={styles.centered}>
                <PDFChart options={postalCodePieChartOptions}></PDFChart>
            </View>
            <View style={styles.centered}></View>
            <Text style={styles.header1}>Stadtgebiete</Text>
            <View style={styles.centered}>
                <PDFChart options={chartOptions}></PDFChart>
            </View>
            <View style={styles.centered}></View>
            <Text style={styles.header1}>Empfehlung</Text>
            <Text style={styles.paragraph}>Frankfurt hat einen hohen Bedarf auf einer relativ kleinen Fläche und ist ein gutes Ziel für den Austausch.</Text>
        </Page>
    </Document>
};

const FireExtinguisherCompanyReport = ({ authentication, resource, settings }) => {
    // Some data aggregations

    const totalFireExtinguisherCount = 66;
    const totalSubsidiaryCount = 22;
    const totalCompanyCount = 2;
    const costSavingsPotential = 43;
    const co2SavingsPotential = 12;

    const headers = ['Name', 'HR', 'Rechtsform', 'Zweck', 'Adresse', 'Etagen', 'Gesch. Fläche', 'Bedarf Feuerlöscher'];
    const chartOptions = {
        series: [{
            name: "Desktops",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
        }],
        chart: {
            type: 'line',
            zoom: {
                enabled: false
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: 'Product Trends by Month',
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        }
    };

    const companyPieChartOptions = {
        series: [425, 844, 312],
        chart: {
            width: 800,
            height: 800,
            type: 'pie',
        },
        labels: ['Siemens', 'Deutsche Bank', 'Mainova'],
    };

    const postalCodePieChartOptions = {
        series: [44, 55, 13],
        chart: {
            width: 800,
            height: 800,
            type: 'pie',
        },
        labels: ['60594', '60382', '60322'],
    };

    return <Document>
        <Page size="A4" style={styles.page}>
            <Text style={styles.pageHeader} fixed>
                ~ Created with The Real Insight ~
            </Text>
            <Image style={styles.logo} src={settings && settings.corporateIdentity && settings.corporateIdentity.logoImage ? userAPI.getResourceURL(authentication.customer, settings.corporateIdentity.logoImage) : 'https://react-pdf.org/images/logo.png'}></Image>
            <View style={styles.section}>
                <Text>Bedarfe an Feuerlöschern für {resource.data.company}</Text>
            </View>
            <Text style={styles.header1}>Zusammenfassung</Text>
            <Text style={styles.paragraph}>
                An den in ingesamt {totalSubsidiaryCount} Niederlassungen von {resource.data.company} besteht insgesamt ein Bedarf an ingesamt {totalFireExtinguisherCount} Feuerlöschern.
                Bei einem Einsparpotential von {costSavingsPotential}€ und {co2SavingsPotential}kg CO2 pro Feuerlöscher entspricht das einem Gesamteinsparpotential von {costSavingsPotential * totalFireExtinguisherCount}€ und {co2SavingsPotential * totalFireExtinguisherCount} kg CO2.
            </Text>
            <Text style={styles.paragraph}>
                Die Niederlassungen befinden sich in insgesamt 5 Städten.
            </Text>
            <Text style={styles.header1}>Bedarfe nach Anzahl Feuerlöscher</Text>
            <PDFTable data={resource.data.buildings} options={{ headers }}></PDFTable>
            <View style={styles.centered}></View>
            <Text style={styles.header1}>Städte</Text>
            <View style={styles.centered}>
                <PDFChart options={chartOptions}></PDFChart>
            </View>
            <View style={styles.centered}></View>
            <Text style={styles.header1}>Empfehlung</Text>
            <Text style={styles.paragraph}>{resource.data.company} hat einen hohen Bedarf auf einer relativ kleinen Fläche und ist ein gutes Ziel für den Austausch.</Text>
        </Page>
    </Document>
};

const CoolantReport = ({ authentication, resource, settings }) => {
    // Some data aggregations

    const totalFireExtinguisherCount = 66;
    const totalSubsidiaryCount = 22;
    const totalCompanyCount = 2;
    const costSavingsPotential = 43;
    const co2SavingsPotential = 12;

    const headers = ['Name', 'HR', 'Rechtsform', 'Zweck', 'Adresse', 'Etagen', 'Gesch. Fläche', 'Bedarf Feuerlöscher'];
    const chartOptions = {
        series: [{
            name: "Desktops",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
        }],
        chart: {
            type: 'line',
            zoom: {
                enabled: false
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: 'Product Trends by Month',
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        }
    };

    const companyPieChartOptions = {
        series: [425, 844, 312],
        chart: {
            width: 800,
            height: 800,
            type: 'pie',
        },
        labels: ['Siemens', 'Deutsche Bank', 'Mainova'],
    };

    const postalCodePieChartOptions = {
        series: [44, 55, 13],
        chart: {
            width: 800,
            height: 800,
            type: 'pie',
        },
        labels: ['60594', '60382', '60322'],
    };

    return <Document>
        <Page size="A4" style={styles.page}>
            <Text style={styles.pageHeader} fixed>
                ~ Created with The Real Insight ~
            </Text>
            <Image style={styles.logo} src={settings && settings.corporateIdentity && settings.corporateIdentity.logoImage ? userAPI.getResourceURL(authentication.customer, settings.corporateIdentity.logoImage) : 'https://react-pdf.org/images/logo.png'}></Image>
            <View style={styles.section}>
                <Text>Kältemittelnutzung und regulatorische Hinweise</Text>
            </View>
            <Text style={styles.header1}>Geänderte F-Gase Verordnung seit dem 1.1.2015</Text>
            <Text style={styles.paragraph}>
                Die EG-VO 517-2014 über teilfluorierte Treibhausgase hat die Verordnung Nr.
                842/2006 aufgehoben. Im Folgenden erfahren Sie, welche praktischen Auswirkungen die neue F-Gase Verordnung, die im Wesentlichen eine neue Gewichtung von
                Kältemittelfüllmengen und die stufenweise Reduzierung der Mengen der in Verkehr gebrachten teilfluorierten Kohlenwasserstoffe vorsieht, auf Ihre Anlagen hat.
            </Text>
            <Text style={styles.header1}>
                Neubau von Kälte- und Klimaanlagen
            </Text>
            <Text style={styles.header2}>Ortsfeste Kälteanlagen</Text>
            <Text style={styles.header2}>Klimaanlagen</Text>
            <Text style={styles.header1}>
                Service und Wartung von Kälte- und Klimaanlagen
            </Text>
            <Text style={styles.header2}>Ortsfeste Kälteanlagen</Text>
            <Text style={styles.header2}>Klimaanlagen</Text>
            <Text style={styles.paragraph}>
                Bis 2030 können die vorhandenen Kältemittel eingesetzt werden.
            </Text>
            <Text style={styles.header1}>
                Verfügbarkeit
            </Text>
            <Text style={styles.paragraph}>
                Neben den Neuregelungen für Kälte- und Klimaanlagen wird die Menge der in Verkehr gebrachten teilfluorierten Kältemittel begrenzt. Basis ist die in den Jahren 2009 - 2012 in der EU hergestellte und in die EU eingeführte durchschnittliche Gesamtmenge, ausgedrückt in CO2
                -Äquivalent.
            </Text>
            <Text style={styles.header1}>
                Dichtheitskontrollen
            </Text>
            <Text style={styles.header2}>
                Ortsfeste Kälteanlagen, Klimaanlagen und Wärmepumpen
            </Text>
            <Text style={styles.paragraph}>
                Ab dem 1.1.2015 ist die bisherige Logik vom 3/30/300 kg Füllgewicht auf CO2-Äquivalent umgestellt.
            </Text>
            <Text style={styles.paragraph}>
                In der Praxis müssen alle Dichtheitsprüfungsintervalle anhand der CO2-Äquivalent-Basis überprüft
                und entsprechend angepasst werden.
                Für Betreiber von Einrichtungen, an denen Dichtheitskontrollen vorgeschrieben sind, besteht
                Dokumentationspflicht.
                Führung von Aufzeichnungen:
                1. Menge und Art der enthaltenen fluorierten Treibhausgase
                2. Alle Mengen die hinzugefügt werden
                3. Werden recycelte oder aufgearbeitete fluorierte Treibhausgase verwendet, so ist Name und Anschrift der Recycling- oder Aufarbeitungsanlage (ggfs. mit Zertifizierungsnummer) anzugeben
                4. Alle Mengen die entnommen werden
                5. Angaben zum Unternehmen, das Arbeiten an der Einrichtung durchgeführt hat
                (ggfs. mit Zertifizierungsnummer)
                6. Ergebnisse der Dichtheitsprüfung (Nachprüfung)
                7. Maßnahmen zur Rückgewinnung, Entsorgung der fluorierten Treibhausgase bei Stilllegung der
                Einrichtung
                Betreiber und ausführende Unternehmen sind verpflichtet die Aufzeichnungen mindestens 5 Jahre
                aufzubewahren.
            </Text>
        </Page>
    </Document>
};

const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
});

export const ReportResource = ({ resource }) => {
    const styles = useStyles();
    const [settings] = useAtom(settingsAtom);
    const [expanded, setExpanded] = useState(false);
    const [report, setReport] = useState(true);
    const [authentication] = useAtom(authenticationAtom);

    useEffect(() => {
        switch (resource.report) {
            case 'FireExtinguisherCompanyReport':
                setReport(<FireExtinguisherCompanyReport authentication={authentication} resource={resource} settings={settings} />);
                break;
            case 'FireExtinguisherReport': setReport(<FireExtinguisherReport authentication={authentication} resource={resource} settings={settings} />);
                break;
            case 'CoolantReport':
            default: setReport(<CoolantReport authentication={authentication} resource={resource} settings={settings} />);
                break;
        }
    }, [resource, settings]);

    return <div className={styles.list}>
        <div className="displayFlex alignItemsCenter">
            <IconButton className="marginRightM" iconProps={{ iconName: expanded ? 'ChevronUp' : 'ChevronDown' }} onClick={() => setExpanded(!expanded)} />
            <FontIcon aria-label="Map" iconName="ReportDocument" className={iconClass} />
            <div className="marginLeftS flexGrow1 displayFlex textL fontWeightBold">{resource.title}</div>
            <PDFDownloadLink document={report} fileName="Feuerlöscherbedarfe Frankfurt.pdf">
                {({ blob, url, loading, error }) =>
                    loading
                        ?
                        'Loading document...'
                        :
                        <ActionButton iconProps={{ iconName: 'Download' }} allowDisabledFocus>
                            Download Report
                        </ActionButton>
                }
            </PDFDownloadLink>
        </div>
        {expanded
            ?
            <div className="marginTopL displayFlex justifyContentCenter">
                <PDFViewer width={600} height={1200}>
                    {report}
                </PDFViewer>
            </div>
            :
            <></>
        }
    </div>
}
