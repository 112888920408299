import '../App.sass';
import { useEffect, useState } from 'react';
import { ActionButton, Checkbox, ChoiceGroup, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, IDropdownOption, Label, Pivot, PivotItem, PrimaryButton, TextField } from '@fluentui/react';
import { userAPI } from '../UserAPI';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import { Address, LegalPerson, EntityQuery, Project, Query } from '@marc.gille-sepehri/tri-model';
import { dataTableStyles } from '../styles';
import { useAdministrationAPI } from '../AdministrationAPI';
import SideBar, { SideBarItem } from '../components/SideBar';
import { FileUpload } from '../components/FileUpload';
import AddressPicker from '../components/AddressPicker';
import LegalPersonPicker from '../components/LegalPersonPicker';

interface Properties {
    processError: (error: any) => void;
}

export default function ProjectPanel(properties: Properties) {
    const { t } = useTranslation();
    const { ingestDocuments } = useAdministrationAPI();
    const [projects, setProjects] = useState([]) as any;
    const [selectedProject, setSelectedProject] = useState() as any;
    const [tab, setTab] = useState('project') as any;
    const [documentType, setDocumentType] = useState('unknown');
    const [objectSelectDialogOpen, setObjectSelectDialogOpen] = useState(false);
    const [partnerSelectDialogOpen, setPartnerSelectDialogOpen] = useState(false);
    const [addressFilter, setAddressFilter] = useState();
    const [legalPerson, setLegalPerson] = useState();

    const columns = [{
        name: 'Name',
        selector: (project: Project) => project.name,
        width: '300px',
        cell: (product: any) => <span>{product.name}</span>,
        sortable: true,
    }, {
        name: 'Customer',
        width: '150px',
        cell: (product: Project) => <span></span>,
        sortable: true,
    }, {
        name: 'Object',
        width: '150px',
        cell: (product: Project) => <span></span>,
        sortable: true,
    }];

    const entitlementDummies = [{
        roles: [],
        partner: new LegalPerson({
            name: 'Pustekuchen AG',
            headquarters: new Address({
                street: 'Universitätsstraße',
                streetNumber: '149',
                postalCode: '44811',
                city: 'Bochum',
            })
        })
    }];

    const entitlementColumns = [{
        name: '',
        width: '50px',
        cell: (document: any) => <div>
            <ActionButton iconProps={{ iconName: 'Delete' }}
                onClick={() => {
                }}>
            </ActionButton>
        </div>,
        sortable: true,
    }, {
        name: 'Partner',
        width: '200px',
        cell: (entitlement: any) => <span>{entitlement.partner.name}</span>,
        sortable: true,
    }, {
        name: 'Address',
        selector: (entitlement: any) => entitlement.partner.headquarters.street,
        width: '400px',
        cell: (entitlement: any) => <div>{entitlement.partner.headquarters.street} {entitlement.partner.headquarters.streetNumber}, {entitlement.partner.headquarters.postalCode} {entitlement.partner.headquarters.city}</div>,
        sortable: true,
    }, {
        name: 'Roles',
        width: '150px',
        cell: (entitlement: any) => <span></span>,
        sortable: true,
    }];

    const objectDummies = [{
        address: new Address({
            street: 'Wasserstraße',
            streetNumber: '60c',
            postalCode: '44803',
            city: 'Bochum',
        })
    }];

    const objectColumns = [{
        name: '',
        width: '50px',
        cell: (document: any) => <div>
            <ActionButton iconProps={{ iconName: 'Delete' }}
                onClick={() => {
                }}>
            </ActionButton>
        </div>,
        sortable: true,
    }, {
        name: 'Address',
        selector: (object: any) => object.address.street,
        width: '400px',
        cell: (object: any) => <div>{object.address.street} {object.address.streetNumber}, {object.address.postalCode} {object.address.city}</div>,
        sortable: true,
    }, {
        name: 'Area',
        width: '150px',
        cell: (object: any) => <span>Ganzes Gebäude</span>,
        sortable: true,
    }];

    const documentDummies = [{
        title: 'Project order',
        synopsis: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ante ante, consequat a sagittis eu, sollicitudin ac neque. Nulla pulvinar libero non sem semper, sit amet dictum erat efficitur. Sed sollicitudin enim vitae velit accumsan vestibulum ac id ligula. Sed sed enim vel arcu mollis aliquet. Aenean et tellus nisl. Quisque vel lobortis sem, sed lacinia sapien. In non vulputate velit. Nulla eu interdum enim, ut faucibus sapien. Nullam vestibulum quam eu facilisis congue. Suspendisse sollicitudin vitae nisi id viverra.',
    }];

    const documentColumns = [{
        name: '',
        width: '50px',
        cell: (document: any) => <div>
            <ActionButton iconProps={{ iconName: 'Document' }}
                onClick={() => {
                }}>
            </ActionButton>
        </div>,
        sortable: true,
    }, {
        name: 'Title',
        selector: (object: any) => object.address.street,
        width: '200px',
        cell: (document: any) => <div>{document.title}</div>,
        sortable: true,
    }, {
        name: 'Received',
        selector: (document: any) => new Date().getTime(),
        width: '150px',
        cell: (document: any) => <div>{new Date().toLocaleDateString()}</div>,
        sortable: true,
    }, {
        name: 'Summary',
        width: '600px',
        cell: (document: any) => <div>{document.synopsis}</div>,
        sortable: true,
    }];


    const selectProject = (project: any) => {
        setSelectedProject(project);
    };

    useEffect(() => {
        const call = async () => {
            try {
                setProjects(await userAPI.query(new Query(new EntityQuery(Project.type))));
            } catch (error) {
                properties.processError(error);
            }
        };

        call();
    }, []);

    const documentTypeOptions: IDropdownOption[] = [
        { key: 'unknown', text: 'Unbekannt' },
        { key: 'lease-contract', text: 'Mietvertrag' },
        { key: 'maintenance-report', text: 'Wartungsprotokoll' },
        { key: 'product-manual', text: 'Produkthandbuch' },
    ];

    const onDocumentsUpload = async (files: FileList) => {
        try {
            const formData = new FormData();

            for (let i = 0; i < files.length; ++i) {
                if (files.item(i) !== null) {
                    // @ts-ignore
                    formData.append(files.item(i).name, files.item(i));
                }
            }

            // const result = await ingestDocuments(authentication.customer, documentType, formData);

            // console.log('Result >>>', result);
        } catch (error) {
            properties.processError(error);
        }
    }

    const setParams = (value: any) => {
        const url = new URL(window.location.href);

        url.searchParams.set('tab', value.key.substring(1));
        window.history.pushState(null, '', url.toString());

        setTab(value.key.substring(1));
    }

    return <><div>
        {selectedProject
            ?
            <div>
                <div className='displayFlex alignItemsCenter'>
                    <div className='flexGrow1 displayFlex textL'>
                        {selectedProject.name}
                    </div>
                    <div className='displayFlex gapM'>
                        <DefaultButton
                            onClick={() => {
                                setSelectedProject(null);
                            }}>
                            Close
                        </DefaultButton>
                        <PrimaryButton
                            onClick={() => {
                                setSelectedProject(null);
                            }}>
                            Save
                        </PrimaryButton>
                    </div>
                </div>
                <div className="marginTopM ">
                    <Pivot aria-label="" onLinkClick={setParams} selectedKey={tab}>
                        <PivotItem id="project" headerText={'General'} itemIcon="UserOptional">
                            <div className='width1200'>
                                <div className="width500">
                                    <TextField className="marginTopL" label={'Name'} value={selectedProject.name} onChange={(e: any, name: any) => setSelectedProject({ ...selectedProject, name })}
                                        style={{ width: '300px' }} />
                                    <TextField label={'Synopsis'} value={selectedProject.description} onChange={(e: any, description: any) => setSelectedProject({ ...selectedProject, description })}
                                        style={{ width: '300px' }} multiline />
                                    <div className='marginTopL textL'>Customer</div>
                                    <div className='marginTopL textM fontWeightBold'>PENNYMARKT GmbH</div>
                                    <div className='textM'>Hohestraße 11</div>
                                    <div className='textM'>55601 Köln</div>
                                    <div className='textM'>HRB 4711, Handelsregister Köln</div>
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem id="objects" headerText={'Objects'}>
                            <div className='width1200'>
                                <div className='marginTopL'>Die folgenden Gebäude und Flächen sind Gegenstand des Projekts:</div>
                                <div className='marginTopL'>
                                    {<ActionButton iconProps={{ iconName: 'Add' }}
                                        onClick={() => {
                                            setObjectSelectDialogOpen(true)
                                        }}>
                                        Add building/space
                                    </ActionButton>}
                                </div>
                                <div className='borderNeutral'>
                                    <DataTable
                                        data={objectDummies}
                                        columns={objectColumns}
                                        customStyles={dataTableStyles}
                                        pagination
                                        paginationPerPage={5}
                                    />
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem id="entitlements" headerText={'Entitlements'}>
                            <div className='width1200'>
                                <div className='marginTopL'>
                                    {<ActionButton iconProps={{ iconName: 'Add' }}
                                        onClick={() => {
                                            setPartnerSelectDialogOpen(true);
                                        }}>
                                        Add partner
                                    </ActionButton>}
                                </div>
                                <div className='borderNeutral'>
                                    <DataTable
                                        data={entitlementDummies}
                                        columns={entitlementColumns}
                                        customStyles={dataTableStyles}
                                        pagination
                                        paginationPerPage={5}
                                    />
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem id="documents" headerText={'Documents'}>
                            <div className='width1200'>
                                <div>
                                    <div>
                                        <Dropdown
                                            label="Document type"
                                            options={documentTypeOptions}
                                            selectedKey={documentType}
                                            onChange={(e: any, item: any) => setDocumentType(item.key)}
                                            styles={{
                                                dropdown: { width: 300 },
                                            }}
                                        />
                                    </div>
                                    <div className="marginTopXL">
                                        <FileUpload onUpload={onDocumentsUpload}></FileUpload>
                                    </div>
                                </div>
                                <div className='marginTopL borderNeutral'>
                                    <DataTable
                                        data={documentDummies}
                                        columns={documentColumns}
                                        customStyles={dataTableStyles}
                                        pagination
                                        paginationPerPage={5}
                                    />
                                </div>
                            </div>
                        </PivotItem>
                    </Pivot>
                </div>
            </div>
            :
            <div>
                <ActionButton iconProps={{ iconName: 'AddToShoppingList' }}
                    onClick={() => {
                        setSelectedProject(new Project({name: `Project ${new Date().toLocaleDateString()}`}));
                    }}>
                    Create project
                </ActionButton>
                <div className='marginTopL borderNeutral'>
                    <DataTable
                        data={projects}
                        columns={columns}
                        customStyles={dataTableStyles}
                        selectableRows
                        selectableRowsSingle
                        onSelectedRowsChange={({ selectedRows }) => {
                            if (selectedRows && selectedRows.length > 0) {
                                selectProject(selectedRows[0]);
                            } else {
                                selectProject(null);
                            }
                        }}
                        pagination
                        paginationPerPage={5}
                    />
                </div>
            </div>
        }
    </div>
        <Dialog
            hidden={!objectSelectDialogOpen}
            onDismiss={() => setObjectSelectDialogOpen(!objectSelectDialogOpen)}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Select object',
                //subText: 'Do you want to send this message without a subject?',
                //maxWidth: 800,
                //width: 800
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <div>
                <div>
                    <Label>Address/Building</Label>
                    <AddressPicker setAddressFilter={setAddressFilter} />
                </div>
            </div>
            <div>
                <ChoiceGroup defaultSelectedKey="wholeBuilding" options={[{ key: 'wholeBuilding', text: 'Ganzes Gebäude' }, { key: 'partOfBuilding', text: 'Teil des Gebäudes' }]} onChange={() => { }} label="Fläche" />
            </div>
            <DialogFooter className="marginTopXXL">
                <PrimaryButton text={t('global.apply')} onClick={() => {
                    console.log('Address >>>', addressFilter);

                    setObjectSelectDialogOpen(!objectSelectDialogOpen);
                }} />
                <DefaultButton onClick={() => {
                    setObjectSelectDialogOpen(!objectSelectDialogOpen);
                }} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
        <Dialog
            hidden={!partnerSelectDialogOpen}
            onDismiss={() => setPartnerSelectDialogOpen(!partnerSelectDialogOpen)}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Partner auswählen',
                //subText: 'Do you want to send this message without a subject?',
                //maxWidth: 800,
                //width: 800
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <div>
                <div>
                    <Label>Partner Companies</Label>
                    <LegalPersonPicker setLegalPerson={setAddressFilter} />
                </div>
                <div className='marginTopM'>
                    <Checkbox label="Partner can involve other partners" />
                </div>
                <div className='marginTopS'>
                    <Checkbox label="Partner can create devices and equipment" />
                </div>
            </div>
            <DialogFooter className="marginTopXXL">
                <PrimaryButton text={t('global.apply')} onClick={() => {
                    setPartnerSelectDialogOpen(!partnerSelectDialogOpen);
                }} />
                <DefaultButton onClick={() => {
                    setPartnerSelectDialogOpen(!partnerSelectDialogOpen);
                }} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
    </>;
}